import { connect } from "react-redux";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

import AccessControl from "./Devices/AccessControl";

function CloudApis({ projectId, cloudApis }) {
  const { t } = useTranslation();
  const commonLocksList = Object.values(cloudApis?.accessControl?.commonLocksList || {});

  return (
    <MDBox>
      {cloudApis?.accessControl?.configured &&
        cloudApis?.accessControl?.accessControlSystem === "omnitec" && (
          <MDBox mt={3}>
            <MDBox mb={2} display="flex">
              <Icon>meeting_room</Icon>
              <MDTypography style={{ marginLeft: 10 }} variant="h5">
                {t("accessControl").toUpperCase()}
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <AccessControl projectId={projectId} lockData={cloudApis?.accessControl} />
              {commonLocksList.map((lockData) => (
                <AccessControl projectId={projectId} lockData={lockData} />
              ))}
            </Grid>
          </MDBox>
        )}
    </MDBox>
  );
}

CloudApis.defaultProps = {
  projectId: "",
  cloudApis: {},
};

CloudApis.propTypes = {
  projectId: PropTypes.string,
  cloudApis: PropTypes.object,
};

const mapStateToProps = (state, { apartmentId, client }) => {
  const { apartmentsReducer, cloudApisReducer } = state;
  let apartment = {};

  if (Object.hasOwnProperty.call(apartmentsReducer, apartmentId)) {
    apartment = apartmentsReducer[apartmentId];
  }

  return {
    cloudApis: client ? cloudApisReducer : apartment?.cloudApis,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CloudApis);

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Header from "layouts/account/components/Header";
import BasicInfo from "layouts/account/components/BasicInfo";
import CustomApp from "layouts/account/components/CustomApp";
import ChangePassword from "layouts/account/components/ChangePassword";
import Notifications from "layouts/account/components/Notifications";
import LogoutAccount from "layouts/account/components/LogoutAccount";

import { useTranslation } from "react-i18next";

function Settings({ database, user }) {
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    const { uid } = user;
    if (uid) {
      try {
        await database.editMainUser(data);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title="account" route="/account" />
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header t={t} user={user} />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo onSubmit={onSubmit} user={user} />
                </Grid>
                <Grid item xs={12}>
                  <CustomApp onSubmit={onSubmit} user={user} />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <Notifications onSubmit={onSubmit} user={user} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <LogoutAccount />
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

Settings.defaultProps = {
  user: {
    uid: "",
  },
};

Settings.propTypes = {
  user: PropTypes.object,
  database: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: state.mainUserReducer,
  user: state.mainUserReducer,
});

export default connect(mapStateToProps)(Settings);

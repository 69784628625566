/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import PropTypes from "prop-types";
import moment from "moment";

// Material Dashboard 2 PRO React components
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React examples
import DataTable from "components/DataTable";

function CellDate({ value }) {
  return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>;
}

function CellState({ value }) {
  if (value) {
    return <Icon color="success">circle</Icon>;
  }
  return <Icon color="error">circle</Icon>;
}

const columns = () => {
  const { t } = useTranslation();

  return [
    { Header: t("name"), accessor: "name", width: 20 },
    { Header: t("apartmentId"), accessor: "nodeId", width: 20 },
  ];
};

function Apartments({ apartments, setOpenConfirm, handleImportApartments }) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardTitle title={t("apartments")} icon="house" bgColor="success" />
      <DataTable
        table={{
          columns: columns(),
          rows: apartments,
        }}
        canSelect
        actionIcon="arrow_forward_ios"
        canCustomActuate
        customAction={(data) => {
          handleImportApartments(data);
        }}
        actionColor="info"
        add={() => {
          setOpenConfirm(true);
        }}
      />
    </Card>
  );
}

CellState.defaultProps = {
  value: "offline",
};

CellState.propTypes = {
  value: PropTypes.string,
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.number,
};

Apartments.defaultProps = {
  apartments: [],
};

Apartments.propTypes = {
  apartments: PropTypes.array,
  setOpenConfirm: PropTypes.func.isRequired,
  handleImportApartments: PropTypes.func.isRequired,
};

export default Apartments;

/**
 * On/Off Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function CardDevice1({ color, device, sendValueServer, deviceState }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={2}
        height="100%"
        bgColor={deviceState ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && !deviceState && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons
            name={deviceState ? "LightbulbOnOutline" : "LightbulbOutline"}
            size={35}
            color={darkMode ? "white" : "black"}
          />
          <MDBox
            mt={-0.5}
            mr={-1.5}
            onClick={(event) => {
              event.stopPropagation();
              sendValueServer(device.onOffAddress, {
                id: device.onOffAddress,
                value: deviceState ? 0 : 1,
              });
            }}
          >
            <Switch checked={!!deviceState} />
          </MDBox>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography
            variant="body2"
            color={deviceState ? "white" : "text"}
            textTransform="capitalize"
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice1
CardDevice1.defaultProps = {
  color: "info",
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice1
CardDevice1.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(CardDevice1, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

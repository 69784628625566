/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Settings page components
import FormField from "components/FormField";
import FormSelector from "components/FormSelector";
import FormDateTimePicker from "components/FormDateTimePicker";
import moment from "moment";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import FormSwitch from "components/FormSwitch";

// NewUser page components

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "projectId",
    "apartmentId",
    "password",
    "startDate",
    "endDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (values.password?.length > 30) {
    errors.password = "Password is too long. Must be less than 30 characters";
  }
  return errors;
};

function ClientFrom(props) {
  const {
    handleSubmit,
    pristine,
    onSubmit,
    actionType,
    projects,
    apartments,
    projectId,
    apartmentId,
    errorMessage,
    change,
    initialValues,
  } = props;

  const [showCreateCode, setShowCreateCode] = useState(false);

  useEffect(() => {
    if (Object.hasOwnProperty.call(apartments, apartmentId)) {
      const { cloudApis } = apartments[apartmentId];
      if (cloudApis) {
        if (cloudApis?.accessControl?.configured) {
          const { accessControlSystem } = cloudApis.accessControl;
          if (accessControlSystem === "omnitec") {
            change("createOmnitecCode", true);
            setShowCreateCode(true);
          } else {
            change("createOmnitecCode", false);
            setShowCreateCode(false);
          }
        } else {
          change("createOmnitecCode", false);
          setShowCreateCode(false);
        }
      } else {
        change("createOmnitecCode", false);
        setShowCreateCode(false);
      }
    }
  }, [projectId, apartmentId]);

  const getApartments = () => {
    if (projects) {
      if (Object.hasOwnProperty.call(projects, projectId)) {
        if (Object.hasOwnProperty.call(projects[projectId], "apartments")) {
          return Object.keys(projects[projectId].apartments);
        }
        return [];
      }
      return [];
    }
    return [];
  };

  const submitData = (data) => {
    const { startDate, endDate } = data;
    let lockIdToSend = "";

    const { cloudApis } = apartments[apartmentId];
    if (cloudApis) {
      if (cloudApis?.accessControl?.configured) {
        const { lockId } = cloudApis.accessControl;
        lockIdToSend = lockId;
      }
    }

    onSubmit({
      ...data,
      lockId: lockIdToSend,
      endDate: moment.isMoment(endDate) ? endDate.valueOf() : endDate,
      startDate: moment.isMoment(startDate) ? startDate.valueOf() : startDate,
    });
  };

  const getApartmentsNames = () => {
    if (projects) {
      if (Object.hasOwnProperty.call(projects, projectId)) {
        if (Object.hasOwnProperty.call(projects[projectId], "apartments")) {
          const apartmentIds = Object.keys(projects[projectId].apartments);
          return Object.values(apartments || {})
            .filter(({ apartmentId: newApartmentId }) => apartmentIds.indexOf(newApartmentId) >= 0)
            .map(({ apartmentId: newApartmentId, name }) => ({
              uid: newApartmentId,
              name,
            }));
        }
        return [];
      }
      return [];
    }
    return [];
  };

  const { t } = useTranslation();
  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(submitData)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t(actionType)} {t("client")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="firstName" component={FormField} label={t("First Name")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastName" component={FormField} label={t("Last Name")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="email"
                    component={FormField}
                    label="Email"
                    disabled={actionType === "edit"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="password" component={FormField} label="Password" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="address" component={FormField} label={t("Address")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="city" component={FormField} label={t("City")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="postcode" component={FormField} label={t("Postcode")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="phoneNumber" component={FormField} label={t("Phone Number")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="projectId"
                    values={Object.keys(projects || {})}
                    names={Object.values(projects || {})}
                    component={FormSelector}
                    label={t("project")}
                    disabled={actionType === "edit"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="apartmentId"
                    values={getApartments()}
                    names={getApartmentsNames()}
                    component={FormSelector}
                    label={t("apartment")}
                    disabled={actionType === "edit"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="startDate" component={FormDateTimePicker} label={t("startDate")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="endDate" component={FormDateTimePicker} label={t("endDate")} />
                </Grid>
                {showCreateCode && actionType === "add" && (
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      flexDirection="row"
                      mt={2}
                      xs={12}
                    >
                      <MDTypography lineHeight="2.5" color="text" variant="body2">
                        {t("randomEntryCode")}
                      </MDTypography>
                      <Field name="createOmnitecCode" component={FormSwitch} />
                    </MDBox>
                  </Grid>
                )}
                {showCreateCode && actionType === "edit" && (
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      flexDirection="row"
                      mt={2}
                      xs={12}
                    >
                      <MDTypography lineHeight="2.5" color="text" variant="body2">
                        {`${t("entryCode")}: ${initialValues.keyboardPwd}`}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MDBox display="flex" alignItems="center" flexDirection="column" mt={2} xs={12}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      size="small"
                      disabled={pristine}
                    >
                      {t("Save")}
                    </MDButton>
                    {errorMessage && (
                      <MDTypography color="error" variant="body2">
                        {errorMessage}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

ClientFrom.defaultProps = {
  projects: {},
  apartments: {},
  initialValues: {},
  projectId: "",
  apartmentId: "",
  errorMessage: "",
};

ClientFrom.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  apartmentId: PropTypes.string,
  projects: PropTypes.object,
  apartments: PropTypes.object,
  errorMessage: PropTypes.string,
  initialValues: PropTypes.object,
};

const selector = formValueSelector("ClientFrom");

const mapStateToProps = (state, props) => {
  const { actionType, uids } = props;
  let userDataToEdit = {};
  const { clientsReducer, projectsReducer, apartmentsReducer } = state;
  if (actionType === "edit" && uids.length === 1) {
    if (Object.prototype.hasOwnProperty.call(clientsReducer, uids[0]))
      userDataToEdit = clientsReducer[uids[0]];
  }

  const { password, startDate, endDate } = userDataToEdit;
  if (!password) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialChars = "!@#%*";
    const passwordLength = 8;
    let newPassword = "";

    for (let i = 0; i <= passwordLength - 1; i += 1) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      newPassword += chars.substring(randomNumber, randomNumber + 1);
    }
    const randomNumber = Math.floor(Math.random() * specialChars.length);
    newPassword += specialChars.substring(randomNumber, randomNumber + 1);

    userDataToEdit.password = newPassword;
  }
  if (!startDate) {
    userDataToEdit.startDate = moment().startOf("day").hour(14).minute(0).valueOf();
  }
  if (!endDate) {
    userDataToEdit.endDate = moment()
      .add(1, "days")
      .startOf("day")
      .hour(12)
      .minute(0)
      .valueOf()
      .valueOf();
  }
  return {
    initialValues: userDataToEdit,
    projects: projectsReducer,
    apartments: apartmentsReducer,
    projectId: selector(state, "projectId"),
    apartmentId: selector(state, "apartmentId"),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "ClientFrom", // a unique identifier for this form
    validate,
  })(ClientFrom)
);

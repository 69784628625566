import React, { useState, useEffect } from "react";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import PropTypes from "prop-types";

function MDAppBar({ tabsInfo, tabValue, setTabValue }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, value) => {
    setTabValue(value);
  };

  return (
    <MDBox pt={3} pb={3}>
      <AppBar position="static">
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          {tabsInfo.map((tabData, index) => {
            const { label, icon } = tabData;
            return (
              <Tab
                label={label}
                // eslint-disable-next-line react/no-array-index-key
                key={`${label}-${index}`}
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    {icon}
                  </Icon>
                }
              />
            );
          })}
        </Tabs>
      </AppBar>
    </MDBox>
  );
}

MDAppBar.defaultProps = {
  tabsInfo: [],
  tabValue: 0,
};

MDAppBar.propTypes = {
  tabsInfo: PropTypes.array,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func.isRequired,
};

export default MDAppBar;

import constants from "../constants";

const {
  INIT_ALARM_SERVER,
  UPDATE_ALARM_STORE,
  SEND_ARM_ALARM_SERVER,
  SEND_DISARM_ALARM_SERVER,
  SEND_CHANGE_PASSWORD_ALARM_SERVER,
} = constants;

export const initAlarmServer = (data) => ({
  type: INIT_ALARM_SERVER,
  data,
});

export const updateAlarmStore = (data) => ({
  type: UPDATE_ALARM_STORE,
  data,
});

export const sendArmAlarmServer = (partial) => ({
  type: SEND_ARM_ALARM_SERVER,
  partial,
});

export const sendDisarmAlarmServer = (pin) => ({
  type: SEND_DISARM_ALARM_SERVER,
  pin,
});

export const changeAlarmPassword = (data) => ({
  type: SEND_CHANGE_PASSWORD_ALARM_SERVER,
  data,
});

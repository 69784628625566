/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

function FormField({ label, input, meta: { touched, invalid, error }, ...custom }) {
  const { t } = useTranslation();
  return (
    <MDInput
      variant="standard"
      fullWidth
      InputLabelProps={{ shrink: true }}
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && t(error)}
      {...input}
      {...custom}
    />
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
  input: {},
  meta: {
    touched: false,
    invalid: false,
    error: "",
  },
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default FormField;

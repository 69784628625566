/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import FormControlLabel from "@mui/material/FormControlLabel";
import MDTypography from "components/MDTypography";

import Checkbox from "@mui/material/Checkbox";

function FormCheckbox({ input, label, ...custom }) {
  return (
    <FormControlLabel
      onChange={(event, value) => {
        input.onChange(value);
      }}
      control={<Checkbox checked={input.value} />}
      label={<MDTypography variant="body2">{label}</MDTypography>}
      {...custom}
    />
  );
}

// Setting default values for the props of FormCheckbox
FormCheckbox.defaultProps = {
  input: {
    value: false,
  },
  label: "",
};

// Typechecking props for FormCheckbox
FormCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};

export default FormCheckbox;

import constants from "../constants";

const { UPDATE_OCCUPANCY, REMOVE_OCCUPANCY, GET_OCCUPANCY_DATA, GET_OCCUPANCY_DATA_XLSX } =
  constants;

export const updateOccupancy = (data) => ({
  type: UPDATE_OCCUPANCY,
  data,
});

export const removeOccupancy = (data) => ({
  type: REMOVE_OCCUPANCY,
  data,
});

export const getOccupancyData = (data) => ({
  type: GET_OCCUPANCY_DATA,
  data,
});

export const getOccupancyDataXlsx = (data) => ({
  type: GET_OCCUPANCY_DATA_XLSX,
  data,
});

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

function RenderFromHelper({ touched, error }) {
  if (!(touched && !!error)) {
    return <div />;
  }
  return <FormHelperText>{touched && error}</FormHelperText>;
}

function FormSelector({ input, label, values, names, meta: { touched, error }, ...rest }) {
  return (
    <FormControl
      error={touched && !!error}
      style={{
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Autocomplete
        id={label}
        value={input.value}
        getOptionLabel={(option) => {
          const foundName = names.find((entry) => entry.uid === option);
          return foundName ? foundName.name : "";
        }}
        isOptionEqualToValue={(option, value) => option.uid === value.uid}
        options={values}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            error={touched && !!error}
            helperText={touched && !!error}
            label={label}
            variant="standard"
          />
        )}
        onChange={(event, value) => input.onChange(value)}
        {...rest}
      />
      {RenderFromHelper({ touched, error })}
    </FormControl>
  );
}

RenderFromHelper.defaultProps = {
  touched: false,
  error: "",
};

RenderFromHelper.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
};

FormSelector.defaultProps = {
  label: " ",
  values: [],
  names: [],
  input: {},
  meta: {
    touched: false,
    invalid: false,
    error: "",
  },
};

FormSelector.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  values: PropTypes.array,
  names: PropTypes.array,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default FormSelector;

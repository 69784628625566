import { Card, Grid } from "@mui/material";
import CardTitle from "components/CardTitle";
import DataTableSelection from "components/DataTableSelection";
import FormSelector from "components/FormSelector";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

function CloudApis({ apartment, accessControl, pristine, handleSubmit, database, change }) {
  const [lockValues, setLockValues] = useState([]);
  const [lockNames, setLockNames] = useState([]);

  const locksList = accessControl?.locksList;
  const commonLocksList = accessControl?.commonLocksList;

  useEffect(() => {
    const newLockValues = [];
    const newLockNames = [];
    if (locksList) {
      Object.values(locksList || {}).forEach((lock) => {
        const { lockId, lockAlias } = lock;
        newLockValues.push(lockId);
        newLockNames.push({
          uid: lockId,
          name: lockAlias,
        });
      });
      setLockValues(newLockValues);
      setLockNames(newLockNames);
    }
  }, [JSON.stringify(locksList)]);

  const onSubmit = (data) => {
    const { cloudApis } = apartment;
    const { lockId, configured, selectedCommonLocksList } = data;
    const newCommonLocksList = {};
    selectedCommonLocksList.forEach((selectedLock) => {
      const { values } = selectedLock;
      if (Object.hasOwnProperty.call(values, "lockId")) {
        const { lockId: commonLockId } = values;
        newCommonLocksList[commonLockId] = values;
      }
    });
    if (locksList) {
      if (Object.hasOwnProperty.call(locksList, lockId)) {
        database.editApartment({
          ...apartment,
          cloudApis: {
            ...cloudApis,
            accessControl: {
              configured,
              ...locksList[lockId],
              accessControlSystem: accessControl?.accessControlSystem,
              commonLocksList: newCommonLocksList,
            },
          },
        });
      }
    }
  };

  const { t } = useTranslation();

  const columns = () => [
    { Header: t("name"), accessor: "lockAlias" },
    { Header: "LOCK", accessor: "lockName" },
    { Header: "ID", accessor: "lockId" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDBox pt={3}>
        <Card>
          <CardTitle title={t("accessControl")} icon="key" bgColor="success" />
          <MDBox pt={3} pb={6} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="configured"
                  values={[true, false]}
                  names={[
                    { uid: true, name: t("true") },
                    { uid: false, name: t("false") },
                  ]}
                  component={FormSelector}
                  label={t("configured")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lockId"
                  values={lockValues}
                  names={lockNames}
                  component={FormSelector}
                  label={t("accessControl")}
                />
              </Grid>
            </Grid>
          </MDBox>
          <CardTitle title={t("commonLocksList")} icon="meeting_room" bgColor="info" />
          <DataTableSelection
            table={{ columns: columns(), rows: Object.values(commonLocksList || {}) }}
            canSearch
            canSelect
            onSelectionChange={(newSelection) => {
              change("selectedCommonLocksList", newSelection);
            }}
            selectedRows={Object.values(apartment?.cloudApis?.accessControl?.commonLocksList || {})}
          />
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" mb={4} xs={12}>
              <MDBox pr={2}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  size="small"
                  name="Save"
                  disabled={pristine}
                >
                  {t("Save")}
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Card>
      </MDBox>
    </form>
  );
}

CloudApis.defaultProps = {
  accessControl: {},
  apartment: {},
};

CloudApis.propTypes = {
  apartment: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  accessControl: PropTypes.object,
  database: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { apartmentId, project }) => {
  const { apartmentsReducer } = state;
  let apartment = {};
  if (Object.hasOwnProperty.call(apartmentsReducer, apartmentId)) {
    apartment = apartmentsReducer[apartmentId];
  }
  return {
    initialValues: apartment?.cloudApis?.accessControl,
    apartment,
    accessControl: project?.cloudApis?.accessControl,
  };
};

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CloudApis", // a unique identifier for this form
    enableReinitialize: true,
  })(CloudApis)
);

import constants from "../constants";

const { UPDATE_CLIENTS, REMOVE_CLIENTS } = constants;

const initialState = {};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_CLIENTS:
      return { ...action.data };
    case REMOVE_CLIENTS:
      return {};
    default:
      return state;
  }
};

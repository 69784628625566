const translations = {
  en: {
    translation: {
      userValidationPending: "User Validation Pending",
      userValidationPendingSubtitle: "We are validating your user, please be patient",
      Settings: "Settings",
      Logout: "Logout",
      userGrantTimeout: "User access grant timedout",
      userHasNoGrant: "User has no access grant",
      Company: "Company",
      "Basic Info": "Basic Info",
      "Custom App": "Customization",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Phone Number": "Phone Number",
      Language: "Languaje",
      Address: "Address",
      City: "City",
      Postcode: "Postcode",
      "VAT Number": "VAT Number",
      // Password
      Success: "Success!",
      Password: "Password",
      PasswordRepeat: "Password Repeat",
      "Wrong Password": "Wrong Password",
      "Unknown Error": "Unknown Error",
      "Different Passwords": "Different Passwords",
      "One special character": "One special character",
      "One capital letter": "One capital letter",
      "Min 8 characters": "Minimum 8 characters",
      "One number": "One number",
      "Change Password": "Change Password",
      "Current Password": "Current Password",
      "New Password": "New Password",
      "Confirm New Password": "Confirm New Password",
      "Update Password": "Update Password",
      "Please follow this guide for a strong password":
        "Please follow this guide for a strong password",
      "Password requirements": "Password requirements",
      Notifications: "Notifications",
      Type: "Type",
      Communication: "Communication",
      "Notify communication errors with Onna BTR devices":
        "Notify communication errors with Onna BTR devices",
      Alerts: "Alerts",
      "Notify technical alerts comming from sensors":
        "Notify technical alerts comming from sensors",
      "Ask Onna Team for account deletion": "Ask Onna Team for account deletion",
      "Delete Account": "Delete Account",
      image: "Image",
      wrongSize: "Wrong size, must be 256x256",
      Profile: "Profile",
      uploadFile: "Upload File",
      Save: "Save",
      Delete: "Delete",
      Required: "Required",
      clients: "Clients",
      client: "Client",
      emailAlreadyExists: "Email Already Exists",
      errorCreateCode: "Error creating lock code",
      users: "Users",
      user: "User",
      "These are the users": "These are the users shared by all your projects",
      stepNotUndo: "This action cannot be undone",
      confirmation: "Are you sure?",
      showing: "Showing",
      of: "of",
      entries: "entries",
      project: "Project",
      removeNotEmpty: "Project is not empty, cannot remove",
      createdAt: "Created At",
      to: "to",
      add: "Add",
      edit: "Edit",
      projects: "Projects",
      "These are the projects": "These are your projects",
      name: "Name",
      onnaDevices: "Onna Devices",
      onnaDevicesDetails: "Onna Devices Details",
      onnaDevice: "Onna Device",
      reservation: "Reserva",
      reservations: "Reservations",
      reservationTime: "Reservation Time (Minutes)",
      unknown: "Unknown",
      accessControls: "Access Controls",
      accessControl: "Access Control",
      code: "Code",
      lockCommunicationError: "Lock is offline",
      roomsLocksList: "Rooms Locks List",
      commonLocksList: "Common Locks List",
      import: "Import",
      system: "System",
      configured: "Configured",
      true: "True",
      false: "False",
      isOverlapping: "Overlaps existing event",
      startTime: "Start Time",
      endTime: "End Time",
      previousDays: "Previuos Days",
      state: "State",
      apartment: "Apartment",
      apartments: "Apartments",
      onnaId: "Onna ID",
      apartmentId: "Apartament ID",
      configuration: "Configuration",
      customizeDashboard: "Customize Dashboard",
      navbar: "Navbar",
      customizeNavbar: "Customize Navbar",
      dark: "Dark",
      transparent: "Transparent",
      white: "White",
      navbarMini: "NavbarMini",
      darkMode: "Dark Mode",
      notifications: "Notifications",
      notificationsDetails: "Notificacions Details",
      message: "Message",
      read: "Read",
      notRead: "Not Read",
      offline: "Offline",
      online: "Online",
      lights: "Lights",
      shutters: "Motors",
      alarms: "Alarms",
      temperature: "Temperature",
      thermostats: "Thermostats",
      supplies: "Supplies",
      others: "Others",
      invoice: "Invoice",
      generate: "Generate",
      startDate: "Start Date",
      endDate: "End Date",
      randomEntryCode: "Create and send random entry code",
      entryCode: "Entry code",
      hotWater: "Hot Water",
      coldWater: "Cold Water",
      electricity: "Electricity",
      gas: "Gas",
      dashboard: "Dashboard",
      consumptions: "Consumptions",
      device: "Device",
      devices: "Devices",
      moment: "Moment",
      moments: "Moments",
      power: "Power",
      energy: "Energy",
      month: "Month",
      year: "Year",
      day: "Day",
      time: "Time",
      lastValue: "Last Value",
      lastDay: "Last Day Value",
      lastMonth: "Last Month Value",
      signIn: "Sign In",
      signUp: "Sign Up",
      enterEmailAndPassword: "Enter email and password",
      password: "Password",
      dontHaveAccount: "Don't have an account yet?",
      forgotPassword: "Forgot password?",
      resetPassword: "Reset Password",
      emailSent: "Email Sent!",
      invalidPassword: "Invalid password",
      invalidEmail: "Invalid email",
      userNotFound: "User not found",
      iAgreeThe: "I agree the",
      joinUsToday: "Join us today",
      termsAndConditions: "Terms and conditions",
      weakPassword: "Weak password",
      weakPassword1:
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter and one number",
      emailInUse: "Email in use",
      alreadyHaveAccount: "You already have an account?",
      addOnnaDevice: "Add Onna device",
      notOpen: "Device connection is not opened. Pud device in link mode",
      alreadyAssigned: "You have no grant to add the device to your account",
      unknownDevice: "Onna ID is unknown",
      unknownError: "Unknown error",
      "IP Address": "IP Address",
      "IP Address Type": "IP Address Type",
      "Hardware Version": "Hardware Version",
      "App Version": "Software Version",
      "Onna Model": "Onna Model",
      Update: "Update",
      download: "Download",
      btnOn: "Turn on",
      btnOff: "Turn off",
      labelOn: "On",
      labelOff: "Off",
      btnUp: "Up",
      btnDown: "Down",
      btnOpen: "Open",
      btnClose: "Close",
      schedule: "Schedule",
      scheduled: "Scheduled",
      value: "Value",
      date: "Date",
      days: "Days",
      schedules: "Schedules",
      weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      uncalibrated: "Uncalibrated",
      locked: "Locked",
      unlocking: "Unlocking",
      unlocked: "Unlocked",
      locking: "Locking",
      unlatched: "Unlached",
      unlatching: "Unlaching",
      motorBlocked: "Motor Blocked",
      undefined: "Undefined",
      loading: "Loading",
      unlock: "Unlock",
      lock: "Lock",
      occupancy: "Occupancy",
      occupied: "Occupied",
      unoccupied: "Unnocupied",
      codeAlreadyExists: "Code already exists",
    },
  },
  es: {
    translation: {
      userValidationPending: "Validación de usuario pendiente",
      userValidationPendingSubtitle: "Estamos validando su usuario, por favor, tenga paciencia",
      Settings: "Ajustes",
      Logout: "Cerrar Sesión",
      userGrantTimeout: "El acceso para el usuario ha caducado",
      userHasNoGrant: "El usuario no tiene acceso",
      Company: "Compañía",
      "Basic Info": "Información Básica",
      "Custom App": "Personalización",
      "First Name": "Nombre",
      "Last Name": "Apellido",
      "Phone Number": "Número de Teléfono",
      Language: "Idioma",
      Address: "Dirección",
      City: "Ciudad",
      Postcode: "Código Postal",
      "VAT Number": "CIF",
      // Password
      Success: "Éxito!",
      Password: "Contraseña",
      PasswordRepeat: "Contraseña Repetida",
      "Wrong Password": "Contraseña Errónea",
      "Unknown Error": "Error Desconocido",
      "Different Passwords": "Contraseñas Diferentes",
      "One special character": "Un Carácter Especial",
      "One capital letter": "Una Letra Mayúscula",
      "Min 8 characters": "Mínimo 8 Caracteres",
      "One number": "Un Número",
      "Change Password": "Cambiar Contraseña",
      "Current Password": "Contraseña Actual",
      "New Password": "Contraseña Nueva",
      "Confirm New Password": "Confirmar Contraseña Nueva",
      "Update Password": "Actualizar Contraseña",
      "Please follow this guide for a strong password":
        "Por favor, siga esta guía para una contraseña segura",
      "Password requirements": "Requerimientos de Contraseña",
      Notifications: "Notificaciones",
      Type: "Tipo",
      Communication: "Comunicación",
      "Notify communication errors with Onna BTR devices":
        "Notificar errores de comunicación con equipos Onna BTR",
      Alerts: "Alertas",
      "Notify technical alerts comming from sensors":
        "Notificar alertas técnicas enviadas por sensores",
      "Ask Onna Team for account deletion": "Solicitar al equipos de Onna que elimine su cuenta",
      "Delete Account": "Eliminar Cuenta",
      Profile: "Perfil",
      uploadFile: "Subir Archivo",
      image: "Imagen",
      wrongSize: "Tamaño incorrecto, debe ser 256x256",
      Save: "Guardar",
      Delete: "Eliminar",
      Required: "Requerido",
      clients: "Clientes",
      client: "Cliente",
      emailAlreadyExists: "El correo ya existe",
      errorCreateCode: "Error al crear código de puerta",
      users: "Usuarios",
      user: "Usuario",
      "These are the users": "Estos son los usuarios compartidos por todos tus proyectos",
      stepNotUndo: "Este paso no se puede deshacer",
      confirmation: "¿Estás seguro?",
      showing: "Mostrando",
      of: "de",
      entries: "entradas",
      project: "Proyecto",
      removeNotEmpty: "El proyecto no está vacío, no se puede eliminar",
      createdAt: "Fecha Creación",
      to: "a",
      add: "Añadir",
      edit: "Editar",
      projects: "Proyectos",
      "These are the projects": "Estos son tus proyectos",
      name: "Nombre",
      onnaDevices: "Dispositivos Onna",
      onnaDevicesDetails: "Detalle de Dispositivos Onna",
      onnaDevice: "Dispositivo Onna",
      reservation: "Reserva",
      reservations: "Reservas",
      reservationTime: "Tiempo de Reserva (Minutos)",
      unknown: "Desconocido",
      accessControls: "Controles de Accesos",
      accessControl: "Control Accesos",
      code: "Código",
      lockCommunicationError: "El equipo no comunica",
      roomsLocksList: "Habitaciones - Listado de puertas",
      commonLocksList: "Zonas Comunes - Listado de puertas",
      import: "Importar",
      system: "Sistema",
      configured: "Configurado",
      true: "Verdadero",
      false: "Falso",
      isOverlapping: "Conflicto con evento existente",
      startTime: "Hora Comienzo",
      endTime: "Hora Fin",
      previousDays: "Días Antelación",
      state: "Estado",
      apartment: "Apartamento",
      apartments: "Apartamentos",
      onnaId: "ID Onna",
      apartmentId: "ID Apartamento",
      configuration: "Configuración",
      customizeDashboard: "Configura tu Dashboard",
      navbar: "Barra Lateral",
      customizeNavbar: "Personaliza tu Barra Lateral",
      dark: "Oscura",
      transparent: "Transparente",
      white: "Blanca",
      navbarMini: "Barra Lateral Mini",
      darkMode: "Modo Oscuro",
      notifications: "Notificaciones",
      notificationsDetails: "Detalle de Notificaciones",
      message: "Mensaje",
      read: "Leídas",
      notRead: "No Leídas",
      offline: "Desconectado",
      online: "Conectado",
      lights: "Luces",
      shutters: "Motores",
      alarms: "Alarmas",
      temperature: "Temperatura",
      thermostats: "Termostatos",
      supplies: "Suministros",
      others: "Otros",
      invoice: "Factura",
      generate: "Generar",
      startDate: "Fecha Comienzo",
      endDate: "Fecha Fin",
      randomEntryCode: "Crear y enviar código de puerta aleatorio",
      entryCode: "Código de puerta",
      hotWater: "Agua Caliente",
      coldWater: "Agua Fría",
      electricity: "Electricidad",
      gas: "Gas",
      dashboard: "Panel de Control",
      consumptions: "Consumos",
      device: "Dispositivo",
      devices: "Dispositivos",
      moment: "Momento",
      moments: "Momentos",
      power: "Potencia",
      energy: "Energía",
      month: "Mes",
      year: "Año",
      day: "Día",
      time: "Hora",
      lastValue: "Valor Actual",
      lastDay: "Valor Último Día",
      lastMonth: "Valor Último Mes",
      signIn: "Acceder",
      signUp: "Darse de alta",
      enterEmailAndPassword: "Introduzca usuario y contraseña",
      password: "Contraseña",
      dontHaveAccount: "¿No tiene una cuenta todavía?",
      forgotPassword: "¿Contraseña olvidada?",
      resetPassword: "Reiniciar contraseña",
      emailSent: "Email Enviado!",
      invalidPassword: "Contraseña inválida",
      invalidEmail: "Email inválido",
      userNotFound: "Usuario no encontrado",
      iAgreeThe: "Acepto los",
      joinUsToday: "Únase a nosotros",
      termsAndConditions: "Términos y condiciones",
      weakPassword: "Contraseña débil",
      weakPassword1:
        "La contraseña debe contener al menos 8 caracteres, una mayúscula, una minúscula y un número",
      emailInUse: "Email en uso",
      alreadyHaveAccount: "¿Ya tiene una cuenta?",
      addOnnaDevice: "Añadir dispositivo Onna",
      notOpen:
        "La conexión con el dispositivo no está abierta. Ponga el dispositivo en modo enlace",
      alreadyAssigned: "No dispone de permisos para asignar el dispositivo a su cuenta",
      unknownDevice: "Onna ID desconocido",
      unknownError: "Error desconocido",
      "IP Address": "Dirección IP",
      "IP Address Type": "Tipo Dirección IP",
      "Hardware Version": "Versión de Hardware",
      "App Version": "Versión de Software",
      "Onna Model": "Modelo Onna",
      Update: "Actualizar",
      download: "Descargar",
      btnOn: "Encender",
      btnOff: "Apagar",
      labelOn: "Encendido",
      labelOff: "Apagado",
      btnUp: "Subir",
      btnDown: "Bajar",
      btnOpen: "Abrir",
      btnClose: "Cerrar",
      schedule: "Programación",
      scheduled: "Programado",
      value: "Valor",
      date: "Fecha",
      days: "Días",
      schedules: "Programaciones",
      weekDays: ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"],
      uncalibrated: "Descalibrado",
      locked: "Bloqueado",
      unlocking: "Desbloqueando",
      unlocked: "Desbloqueado",
      locking: "Bloqueando",
      unlatched: "Desbloqueado",
      unlatching: "Desbloqueando",
      motorBlocked: "Motor Bloqueado",
      undefined: "Desconocido",
      loading: "Cargando",
      unlock: "Desbloquear",
      lock: "Bloquear",
      occupancy: "Ocupación",
      occupied: "Ocupado",
      unoccupied: "Desocupado",
      codeAlreadyExists: "El código ya existe",
    },
  },
};

export default translations;

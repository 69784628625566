/**
 * On/Off Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import Chart from "components/Chart";

function CardDevice7({ device, deviceState }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const labelAnalogValue = () => `${deviceState?.toFixed(device.decimalPlaces)} ${device.sufix}`;

  const labelAnalogFixed = () => {
    const list = device.fixedValues;
    let msg = "noMatch".toUpperCase();
    for (let i = 0; i < list.length; i += 1) {
      if (parseInt(list[i].value, 10) === Math.round(deviceState)) {
        msg = list[i].label;
      }
    }
    return msg;
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <Chart device={device} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          {device.trend && (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MDBox display="flex" sx={{ minWidth: 150 }}>
                <Icons name="Chart" size={40} color={darkMode ? "white" : "black"} />
              </MDBox>
            </MDBox>
          )}
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          {device.visualizationType === "value" && (
            <MDTypography>{labelAnalogValue()}</MDTypography>
          )}
          {device.visualizationType === "fixed" && (
            <MDTypography>{labelAnalogFixed()}</MDTypography>
          )}
          {device.visualizationType === "text" && <MDTypography>{deviceState}</MDTypography>}
          <MDTypography variant="body2" textTransform="capitalize">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice7
CardDevice7.defaultProps = {
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice7
CardDevice7.propTypes = {
  device: PropTypes.object,
  deviceState: PropTypes.number,
};

export default memo(CardDevice7, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

/**
 * Access Control Nuki
 */

// prop-types is a library for typechecking of props
import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useTranslation } from "react-i18next";

import ModalDevice14 from "layouts/apartment/Devices/DeviceModals/ModalDevice14";

const nukiStateObj = (lockState) => {
  switch (lockState) {
    case 0:
      return {
        keyText: "uncalibrated",
        iconName: "Alert",
        color: "red",
      };
    case 1:
      return {
        keyText: "locked",
        iconName: "DoorClosed",
        color: "red",
      };
    case 2:
      return {
        keyText: "unlocking",
        iconName: "Autorenew",
        color: "orange",
      };
    case 3:
      return {
        keyText: "unlocked",
        iconName: "DoorOpen",
        color: "green",
      };
    case 4:
      return {
        keyText: "locking",
        iconName: "Autorenew",
        color: "orange",
      };
    case 5:
      return {
        keyText: "unlatched",
        iconName: "DoorOpen",
        color: "green",
      };
    case 6:
      return {
        keyText: "unlocked",
        iconName: "DoorOpen",
        color: "green",
      };
    case 7:
      return {
        keyText: "unlatching",
        iconName: "Autorenew",
        color: "orange",
      };
    case 254:
      return {
        keyText: "motorBlocked",
        iconName: "Alert",
        color: "red",
      };
    case 255:
      return {
        keyText: "undefined",
        iconName: "Alert",
        color: "red",
      };
    default:
      return {
        keyText: "loading",
        iconName: "Autorenew",
        color: "gray",
      };
  }
};

function CardDevice14(props) {
  const { device } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [nukiState, setNukiState] = useState(1);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ModalDevice14 device={device} nukiState={nukiState} setNukiState={setNukiState} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <MDBox>
              <Icons
                name={nukiStateObj(nukiState)?.iconName}
                color={nukiStateObj(nukiState)?.color}
                size={35}
              />
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="body2" color="text" align="center">
                {10}%
              </MDTypography>
              <Icons
                name={45 > 50 ? "Battery70" : "Battery10"}
                color={darkMode ? "white" : "red"}
                size={25}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox lineHeight={1} display="flex" justifyContent="space-around">
          <MDTypography variant="h4" ml={1} color="text" align="center">
            {t(nukiStateObj(nukiState)?.keyText).toUpperCase()}
          </MDTypography>
        </MDBox>
        <MDBox lineHeight={1}>
          <MDTypography variant="body2" color="text" textTransform="capitalize">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice14
CardDevice14.defaultProps = {
  device: {},
};

// Typechecking props for the CardDevice14
CardDevice14.propTypes = {
  device: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const {
    knxAddressReducer = [],
    trendsReducer: { trendsHistory },
  } = state;
  const { device } = props;
  const addressObject = {};
  const keys = Object.keys(device);
  keys.forEach((key) => {
    if (key.includes("Address") && device[key]) {
      const address = knxAddressReducer.find((foundAddress) => foundAddress.id === device[key]);
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (address) addressObject[newKey] = address.value;
    }
  });

  return {
    ...addressObject,
    trendsHistory,
  };
};

export default connect(mapStateToProps)(
  memo(CardDevice14, (prevProps, nextProps) => {
    const { device } = prevProps;
    const { nodeId: nodeIdPrev } = device;
    const {
      device: { nodeId },
    } = nextProps;
    const test = [];
    const keys = Object.keys(device);
    keys.forEach((key) => {
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (key.includes("Address") && device[key]) {
        test.push(prevProps[newKey] !== nextProps[key]);
      }
    });
    return test.indexOf(true) === -1 && nodeIdPrev === nodeId;
  })
);

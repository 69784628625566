/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import { initializeApp } from "firebase/app";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { getAuth, signOut } from "firebase/auth";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function ValidationPending() {
  const { t } = useTranslation();
  const auth = getAuth();

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("userValidationPending")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("userValidationPendingSubtitle")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} style={{ display: "flex", justifyContent: "center" }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              signOut(auth);
            }}
            sx={{ height: "100%" }}
          >
            {t("Logout")}
          </MDButton>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ValidationPending;

/**
 * Dimming Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSlider from "components/MDSlider";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function ModalDevice9({ device, sendValueServer, deviceState }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const {
    // valueAddress,
    visualizationType,
    // fastStep,
    slowStep,
    // decimalPlaces,
    sufix,
    fixedValues,
    minValue,
    maxValue,
  } = device;

  const labelAnalogValue = () => `${deviceState?.toFixed(device.decimalPlaces)} ${sufix}`;

  const labelAnalogFixed = () => {
    let msg = "noMatch".toUpperCase();
    for (let i = 0; i < fixedValues.length; i += 1) {
      if (parseInt(fixedValues[i].value, 10) === Math.round(deviceState)) {
        msg = fixedValues[i].label;
      }
    }
    return msg;
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        bgColor="white"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        {visualizationType === "value" && (
          <MDBox mt={4} mx={2}>
            <MDSlider
              onChange={(value) => {
                sendValueServer(device.valueAddress, {
                  id: device.valueAddress,
                  value,
                });
              }}
              value={deviceState}
              min={parseFloat(minValue)}
              max={parseFloat(maxValue)}
              step={parseFloat(slowStep)}
              color="error"
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </MDBox>
        )}
        {visualizationType === "fixed" && (
          <Grid container mt={4} mb={4} display="flex" justifyContent="center">
            {fixedValues.map((item) => {
              const { value, label } = item;
              return (
                <MDBox m={1} key={value + label}>
                  <MDButton
                    color="info"
                    onClick={() => {
                      sendValueServer(device.valueAddress, {
                        id: device.valueAddress,
                        value: parseFloat(value),
                      });
                    }}
                  >
                    <MDTypography color="white" textTransform="capitalize">
                      {label}
                    </MDTypography>
                  </MDButton>
                </MDBox>
              );
            })}
          </Grid>
        )}
        <MDBox mb={1} lineHeight={1} display="flex" justifyContent="center">
          {device.visualizationType === "value" && (
            <MDTypography variant="h2">{labelAnalogValue()}</MDTypography>
          )}
          {device.visualizationType === "fixed" && (
            <MDTypography variant="h2">{labelAnalogFixed()}</MDTypography>
          )}
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" color="text" textTransform="capitalize">
            {device?.name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ModalDevice9
ModalDevice9.defaultProps = {
  // color: "info",
  device: {},
  deviceState: 0,
};

// Typechecking props for the ModalDevice9
ModalDevice9.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(ModalDevice9, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

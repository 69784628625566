/**
 * On/Off Lamp
 */

// prop-types is a library for typechecking of props
import React, { useState, memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ModalDevice9 from "layouts/apartment/Devices/DeviceModals/ModalDevice9";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function CardDevice9({ device, deviceState, sendValueServer }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const labelAnalogValue = () => `${deviceState?.toFixed(device.decimalPlaces)} ${device.sufix}`;

  const labelAnalogFixed = () => {
    const list = device.fixedValues;
    let msg = "noMatch".toUpperCase();
    for (let i = 0; i < list.length; i += 1) {
      if (parseInt(list[i].value, 10) === Math.round(deviceState)) {
        msg = list[i].label;
      }
    }
    return msg;
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ModalDevice9
            device={device}
            sendValueServer={sendValueServer}
            deviceState={deviceState}
          />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => {
          setOpen(true);
        }}
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          {device.visualizationType === "icon" && (
            <Icons
              name={deviceState ? device.iconOn : device.iconOff}
              size={40}
              color={darkMode ? "white" : "black"}
            />
          )}
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          {device.visualizationType === "value" && (
            <MDTypography>{labelAnalogValue()}</MDTypography>
          )}
          {device.visualizationType === "fixed" && (
            <MDTypography>{labelAnalogFixed()}</MDTypography>
          )}
          <MDTypography variant="body2" textTransform="capitalize">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice9
CardDevice9.defaultProps = {
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice9
CardDevice9.propTypes = {
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(CardDevice9, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function CardTitle({ icon, title, ...rest }) {
  return (
    <MDBox display="flex">
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="4rem"
        height="4rem"
        variant="gradient"
        shadow="md"
        borderRadius="xl"
        ml={3}
        mt={-2}
        {...rest}
      >
        <Icon fontSize="medium" color="inherit">
          {icon}
        </Icon>
      </MDBox>
      <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
        {title}
      </MDTypography>
    </MDBox>
  );
}

CardTitle.defaultProps = {
  color: "white",
  bgColor: "info",
};

CardTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

export default CardTitle;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";

function AccessControlForm({ onSubmit, getLocks, projectId }) {
  const [lockList, setLockList] = useState([]);
  const { t } = useTranslation();

  const columns = () => [
    { Header: t("name"), accessor: "lockAlias" },
    { Header: "LOCK", accessor: "lockName" },
    { Header: "ID", accessor: "lockId" },
  ];

  useEffect(() => {
    getLocks({
      projectId,
      cb: (response) => {
        if (response !== false && Array.isArray(response)) {
          const newList = [];
          response.forEach((lock) => {
            const { lockAlias, lockId, lockName } = lock;
            newList.push({ lockAlias, lockId, lockName });
          });
          setLockList(newList);
        }
      },
    });
  }, []);

  return (
    <MDBox my={3}>
      <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox
            height="4rem"
            bgColor="info"
            variant="gradient"
            coloredShadow="info"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            mt={-3}
            mx={10}
            px={2}
          >
            {t("add")} {t("accessControl")}
          </MDBox>
          <MDBox m={3}>
            <DataTable
              table={{ columns: columns(), rows: lockList }}
              canSearch
              canSelect
              canCustomActuate
              customAction={(data) => {
                onSubmit(data);
              }}
              // canActuate
              // action={(data) => {
              //   const uid = data?.original?.uid;
              //   if (uid) {
              //     navigate(`/projects/${projectId}/reservation/${uid}`);
              //   }
              // }}
              // add={addLock}
              // remove={removeLock}
            />
          </MDBox>
        </Card>
      </Grid>
    </MDBox>
  );
}

AccessControlForm.defaultProps = {
  projectId: "",
};

AccessControlForm.propTypes = {
  getLocks: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

export default AccessControlForm;

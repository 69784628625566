/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import {
  VictoryChart,
  VictoryBar,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLabel,
  VictoryClipContainer,
} from "victory";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icons from "components/CustomIcons";
import colors from "assets/theme/base/colors";
import { timeHour } from "d3-time";
import { timeFormat } from "d3-time-format";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import { Modal } from "@mui/material";
import DownloadForm from "./downloadForm";

function CustomLabel(props) {
  const { type, decimalPlaces, sufix, getTimeFormat } = props;
  const { x, datum } = props;
  const { t } = useTranslation();
  const typeLabel = [t("time"), t("day"), t("month")];
  const initialX = x;
  const initialY = 10;
  return (
    <>
      <text x={initialX} y={initialY} fontSize={16} textAnchor="middle">
        {`${typeLabel[type]}: ${getTimeFormat()(datum?.x)}`}
      </text>
      <text x={initialX} y={initialY + 20} fontSize={16} textAnchor="middle">
        {`${parseFloat(datum?.y).toFixed(decimalPlaces)} ${sufix}`}
      </text>
    </>
  );
}

function CustomLine(props) {
  const { x, height } = props;
  return (
    <g>
      <line
        style={{ opacity: 0.5 }}
        transform={`translate(${x}, 50)`}
        x1={0}
        y1={height - 100}
        stroke="grey"
        strokeWidth={2}
      />
    </g>
  );
}

function Chart(props) {
  const { trendData, newDateInterval, dateInterval, project } = props;
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const date = new Date();
  const [yLabelSize, setYLabelSize] = useState(50);
  const [showChart, setShowChart] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [formatedData, setFormatedData] = useState([]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setShowChart(true);
    }, 100);
    newDateInterval({
      startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 10),
    });
    return () => {
      setShowChart(false);
    };
  }, []);

  useEffect(() => {
    let yValueSize = 0;
    let newFormatedData = [];
    if (trendData) {
      newFormatedData = trendData.map(({ _time, _value }) => {
        const currentYValueSize = _value.toString().length;
        if (currentYValueSize > yValueSize) yValueSize = currentYValueSize;
        return {
          x: new Date(_time),
          y: _value,
        };
      });
    }
    const newLabelSize = yValueSize * 8 + 15;
    if (newLabelSize < 50) {
      setYLabelSize(50);
    } else if (newLabelSize < 100) {
      setYLabelSize(newLabelSize);
    } else {
      setYLabelSize(100);
    }
    setFormatedData(newFormatedData);
  }, [trendData]);

  const getTitleTimeFormat = () => timeFormat("%d/%m/%Y");

  const getTimeFormat = () => timeFormat("%H:%M");

  const getTimeRange = () => timeHour.every(6).range(dateInterval.startDate, dateInterval.endDate);

  const moveInterval = (direction) => {
    const { startDate } = dateInterval;
    const [year, month, day] = [startDate.getFullYear(), startDate.getMonth(), startDate.getDate()];
    const newDay = day + (direction === "up" ? 1 : -1);
    const newStartDate = new Date(year, month, newDay);
    const newEndDate = new Date(year, month, newDay + 1);
    newDateInterval({ startDate: newStartDate, endDate: newEndDate });
  };

  const getTable = () => (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
      <style>{`
        .table-container-chart {
            width: 75%;
            max-height: 300px;
            overflow-y: auto;
            margin: 0px 0;
        }
        .table-chart {
            border-collapse: collapse;
            width: 100%;
        }
        .table-chart th, td {
            font-size: 14px;
            border-bottom: 1px solid grey;
            padding: 4px;
            text-align: center;
        }
        .table-chart th {
            background-color: white;
            position: sticky;
            top: 0;
            z-index: 1;
        }
        .table-chart tr:last-child td {
            border-bottom: none;
        }
      `}</style>
      <div className="table-container-chart">
        <table className="table-chart">
          <thead>
            <tr>
              <th>{t("date")}</th>
              <th>{t("value")}</th>
            </tr>
          </thead>
          <tbody>
            {formatedData.map((row) => (
              <tr key={row.x.valueOf()}>
                <td style={{ width: "50%" }}>
                  {row.x.toLocaleString(undefined, { hourCycle: "h23" })}
                </td>
                <td style={{ width: "50%" }}>{row.y}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const getChart = () => (
    <MDBox
      flexGrow={1}
      m={2}
      ref={(el) => {
        ref.current = el;
      }}
    >
      <VictoryChart
        responsive={false}
        animate={{
          duration: 400,
          onLoad: { duration: 400 },
        }}
        domain={{
          x: [dateInterval.startDate, dateInterval.endDate],
        }}
        domainPadding={{ x: 10 }}
        padding={{ top: 50, left: yLabelSize, bottom: 50, right: 50 }}
        scale={{ y: "linear" }}
        height={height}
        width={width}
        containerComponent={
          <VictoryVoronoiContainer
            responsive={false}
            style={{ strokeWidth: 1 }}
            cursorDimension="x"
            voronoiDimension="x"
            labels={() => "ABC"}
            zoomDimension="x"
            labelComponent={<CustomLine height={height} />}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            ticks: { stroke: darkMode ? "white" : "grey", size: 5 },
            axis: { stroke: darkMode ? "white" : "grey", size: 5 },
            tickLabels: { fill: darkMode ? "white" : "grey" },
          }}
        />
        <VictoryAxis
          crossAxis={false}
          orientation="bottom"
          name="time-axis"
          tickFormat={getTimeFormat()}
          tickValues={getTimeRange()}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
          style={{
            ticks: { stroke: darkMode ? "white" : "grey", size: 5 },
            axis: { stroke: darkMode ? "white" : "grey", size: 5 },
            tickLabels: { fill: darkMode ? "white" : "grey" },
          }}
        />
        <VictoryBar
          name="value"
          key="bar"
          style={{
            data: { fill: colors.info.main },
          }}
          barWidth={5}
          interpolation="linear"
          labels={() => {}}
          groupComponent={<VictoryClipContainer />}
          data={formatedData}
          labelComponent={
            <VictoryTooltip
              cornerRadius={4}
              flyoutWidth={130}
              flyoutHeight={60}
              pointerLength={0}
              flyoutStyle={{
                strokeWidth: 2,
                fill: "white",
                fillOpacity: 0.6,
                pointerEvents: "none",
                stroke: darkMode ? "white" : "grey",
              }}
              center={{ y: 15 }}
              centerOffset={{ x: 0 }}
              labelComponent={
                <CustomLabel getTimeFormat={getTimeFormat} sufix="" decimalPlaces="0" />
              }
            />
          }
        />
      </VictoryChart>
    </MDBox>
  );

  return (
    <MDBox display="flex" flexDirection="column" sx={{ height: "50vh", overflow: "hidden" }}>
      <Modal
        open={openDownload}
        onClose={() => {
          setOpenDownload(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <DownloadForm project={project} />
        </MDBox>
      </Modal>
      <MDBox display="flex" mb={2} justifyContent="center" alignItems="center">
        <MDBox
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            moveInterval("down");
          }}
        >
          <Icons name="ChevronLeft" size={40} color={colors.info.main} />
        </MDBox>

        <MDBox>
          <MDTypography style={{ margin: 10 }} color="text" textTransform="capitalize">
            {getTitleTimeFormat()(dateInterval.startDate)}
          </MDTypography>
        </MDBox>
        <MDBox
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            moveInterval("up");
          }}
        >
          <Icons name="ChevronRight" size={40} color={colors.info.main} />
        </MDBox>
        <MDBox
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => {
            setShowChart(true);
            setShowTable(false);
          }}
        >
          <Icons name="Chart" size={40} color="grey" />
        </MDBox>
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 16,
          }}
          onClick={() => {
            setShowChart(false);
            setShowTable(true);
          }}
        >
          <Icons name="List" size={40} color="grey" />
        </MDBox>
        <MDBox
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 16,
          }}
          onClick={() => {
            setOpenDownload(true);
          }}
        >
          <Icons name="Download" size={40} color="grey" />
        </MDBox>
      </MDBox>
      {showChart && getChart()}
      {showTable && getTable()}
    </MDBox>
  );
}

Chart.defaultProps = {};

Chart.propTypes = {};

CustomLabel.defaultProps = {
  type: 0,
  decimalPlaces: 0,
  sufix: "",
};

CustomLabel.propTypes = {
  getTimeFormat: PropTypes.func.isRequired,
  type: PropTypes.number,
  decimalPlaces: PropTypes.string,
  sufix: PropTypes.string,
};

CustomLine.defaultProps = {
  x: 0,
  height: 0,
};

CustomLine.propTypes = {
  x: PropTypes.number,
  height: PropTypes.number,
};

export default Chart;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Card from "@mui/material/Card";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import Calendar from "components/Calendar";
import enLocale from "@fullcalendar/core/locales/en-gb";
import esLocale from "@fullcalendar/core/locales/es";

function ReservationCalendar({ events, handleDates, handleDateSelect, handleEventClick }) {
  // const handleEventAdd = (addInfo) => {
  //   // this.props.createEvent(addInfo.event.toPlainObject()).catch(() => {
  //   //   reportNetworkError();
  //   //   addInfo.revert();
  //   // });
  //   console.log("Add");
  //   const { reservationTime } = reservation;

  //   console.log(reservationTime);
  //   console.log(addInfo.event.toPlainObject());
  // };

  // const handleEventChange = (changeInfo) => {
  //   // this.props.updateEvent(changeInfo.event.toPlainObject()).catch(() => {
  //   //   reportNetworkError();
  //   //   changeInfo.revert();
  //   // });

  //   setEvent(changeInfo.event.toPlainObject());
  //   setActionType("edit");
  //   setOpen(true);
  // };

  // const handleEventRemove = (removeInfo) => {
  //   // this.props.deleteEvent(removeInfo.event.id).catch(() => {
  //   //   reportNetworkError();
  //   //   removeInfo.revert();
  //   // });
  //   console.log("Remove");

  //   setEvent(removeInfo.event.toPlainObject());
  //   setActionType("edit");
  //   setOpen(true);
  // };

  // const renderEventContent = (eventInfo) => (
  //   <>
  //     <b>{eventInfo.timeText}</b>
  //     <i>{eventInfo.event.title}</i>
  //   </>
  // );

  const { t, i18n } = useTranslation();
  return (
    <Card>
      <CardTitle title={t("reservation")} icon="access_time" bgColor="warning" />
      <Calendar
        headerToolbar={{
          start: "today prev,next",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        minTime="07:00:00"
        maxTime="21:00:00"
        dayMaxEventRows={4}
        initialView="timeGridWeek"
        initialDate={Date.now()}
        events={events}
        locale={i18n.language === "es" ? esLocale : enLocale}
        contentHeight={500}
        height={500}
        eventOverlap={false}
        selectable
        // editable
        datesSet={handleDates}
        select={handleDateSelect}
        // eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        // eventAdd={handleEventAdd}
        // eventChange={handleEventChange} // called for drag-n-drop/resize
        // eventRemove={handleEventRemove}
      />
    </Card>
  );
}

ReservationCalendar.defaultProps = {
  events: [],
  handleDates: () => {},
  handleDateSelect: () => {},
  handleEventClick: () => {},
};

ReservationCalendar.propTypes = {
  events: PropTypes.array,
  handleDates: PropTypes.func,
  handleDateSelect: PropTypes.func,
  handleEventClick: PropTypes.func,
};

export default ReservationCalendar;

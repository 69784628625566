/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import moment from "moment";

function Notifications(props) {
  const { alert } = props;
  const { t } = useTranslation();

  const [titleSB, setTitleSB] = useState("");
  const [contentSB, setContentSB] = useState("");
  const [dateTimeSB, setDateTimeSB] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    const { type, title, content } = alert;
    setTitleSB(t(title));
    setContentSB(t(content));
    const momentNow = moment();
    setDateTimeSB(momentNow.format("HH:mm:ss"));

    switch (type) {
      case "success":
        openSuccessSB();
        break;
      case "info":
        openInfoSB();
        break;
      case "warning":
        openWarningSB();
        break;
      case "error":
        openErrorSB();
        break;
      default:
        break;
    }
  }, [alert]);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="star"
      title={titleSB}
      content={contentSB}
      dateTime={dateTimeSB}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="star"
      title={titleSB}
      content={contentSB}
      dateTime={dateTimeSB}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="warning"
      title={titleSB}
      content={contentSB}
      dateTime={dateTimeSB}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="error"
      title={titleSB}
      content={contentSB}
      dateTime={dateTimeSB}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <div>
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}
    </div>
  );
}

Notifications.defaultProps = {};

Notifications.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { globalAlertReducer } = state;

  return {
    alert: globalAlertReducer,
  };
};

export default connect(mapStateToProps)(Notifications);

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { getAuth, EmailAuthProvider, signInWithCredential, updatePassword } from "firebase/auth";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useTranslation } from "react-i18next";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("text");
  const { t } = useTranslation();

  const reauthenticate = (auth, user) => {
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    return signInWithCredential(auth, cred);
  };

  const changePassword = async () => {
    if (newPassword === newPassword2) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        await reauthenticate(auth, user);
        await updatePassword(user, newPassword);
        setMessage(t("Success"));
        setMessageColor("text");
      } catch (error) {
        setMessageColor("error");
        switch (error.code) {
          case "auth/wrong-password":
            setMessage(t("Wrong Password"));
            break;
          default:
            setMessage(t("Unknown Error"));
            break;
        }
      }
    } else {
      setMessage(t("Different Passwords"));
    }
  };

  const passwordRequirements = [
    t("One special character"),
    t("One capital letter"),
    t("Min 8 characters"),
    t("One number"),
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">{t("Change Password")}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label={t("Current Password")}
              onChange={(event) => {
                setCurrentPassword(event.target.value);
              }}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label={t("New Password")}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              onChange={(event) => {
                setNewPassword2(event.target.value);
              }}
              label={t("Confirm New Password")}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h5">{t("Password requirements")}</MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {renderPasswordRequirements}
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" xs={12} mb={1}>
              <MDTypography
                variant="button"
                color={messageColor}
                fontWeight="regular"
                verticalAlign="middle"
              >
                {message}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="center" xs={12}>
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  changePassword();
                }}
              >
                {t("Update Password")}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;

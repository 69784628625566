import constants from "../constants";

const { SAVE_MACROS_STORE, UPDATE_MACROS_STORE } = constants;

const initialState = {
  macros: {},
};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case SAVE_MACROS_STORE: {
      return { macros: action.data };
    }
    case UPDATE_MACROS_STORE: {
      const { macros, apartmentId } = action.data;
      if (apartmentId) {
        return {
          macros: {
            ...state.macros,
            [apartmentId]: macros,
          },
        };
      }
      return { macros };
    }
    default:
      return state;
  }
};

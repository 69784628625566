/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { getAuth, signOut } from "firebase/auth";

function LogoutAccount() {
  const { t } = useTranslation();
  const auth = getAuth();
  return (
    <MDButton
      variant="gradient"
      color="error"
      onClick={() => {
        signOut(auth);
      }}
      sx={{ height: "100%" }}
    >
      {t("Logout")}
    </MDButton>
  );
}

export default LogoutAccount;

import constants from "../constants";

const { UPDATE_MAIN_USER, REMOVE_MAIN_USER } = constants;

const initialState = {
  communication: false,
  alerts: false,
  customApp: {
    customLang: {
      en: {
        titleWelcome: "Hello! We hope you enjoy your stay with us",
        titleRooms: "Your apartment",
      },
      es: {
        titleWelcome: "¡Hola! Esperamos que disfrutes de tu estancia con nosotros",
        titleRooms: "Tu apartamento",
      },
      fr: {
        titleWelcome: "¡Hola! Esperamos que disfrutes de tu estancia con nosotros",
        titleRooms: "Tu apartamento",
      },
    },
  },
  isValidated: false,
  isMainUser: false,
  isClient: false,
};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_MAIN_USER:
      return { ...state, ...action.data };
    case REMOVE_MAIN_USER:
      return { initialState };
    default:
      return state;
  }
};

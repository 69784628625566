/**
 * Thermostat
 */

// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// @mui material components

import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context

const nukiStateObj = (lockState) => {
  switch (lockState) {
    case 0:
      return {
        keyText: "uncalibrated",
        iconName: "Alert",
        color: "red",
      };
    case 1:
      return {
        keyText: "locked",
        iconName: "DoorClosed",
        color: "red",
      };
    case 2:
      return {
        keyText: "unlocking",
        iconName: "Autorenew",
        color: "orange",
      };
    case 3:
      return {
        keyText: "unlocked",
        iconName: "DoorOpen",
        color: "green",
      };
    case 4:
      return {
        keyText: "locking",
        iconName: "Autorenew",
        color: "orange",
      };
    case 5:
      return {
        keyText: "unlatched",
        iconName: "DoorOpen",
        color: "green",
      };
    case 6:
      return {
        keyText: "unlocked",
        iconName: "DoorOpen",
        color: "green",
      };
    case 7:
      return {
        keyText: "unlatching",
        iconName: "Autorenew",
        color: "orange",
      };
    case 254:
      return {
        keyText: "motorBlocked",
        iconName: "Alert",
        color: "red",
      };
    case 255:
      return {
        keyText: "undefined",
        iconName: "Alert",
        color: "red",
      };
    default:
      return {
        keyText: "loading",
        iconName: "Autorenew",
        color: "gray",
      };
  }
};

function ModalDevice14(props) {
  const { device, nukiState, setNukiState } = props;

  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <MDBox>
              <Icons
                name={nukiStateObj(nukiState)?.iconName}
                color={nukiStateObj(nukiState)?.color}
                size={35}
              />
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="body2" color="text" align="center">
                {10}%
              </MDTypography>
              <Icons
                name={45 > 50 ? "Battery70" : "Battery10"}
                color={darkMode ? "white" : "red"}
                size={25}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox lineHeight={1} display="flex" justifyContent="space-around">
          <MDTypography variant="h2" ml={2} color="text" align="center">
            {t(nukiStateObj(nukiState)?.keyText).toUpperCase()}
          </MDTypography>
        </MDBox>
        <MDBox mt={1} display="flex" justifyContent="center" lineHeight={1}>
          <MDBox m={1} key="unlock">
            <MDButton
              color="info"
              onClick={() => {
                if (nukiState !== 6) {
                  setNukiState(2);
                  setTimeout(() => {
                    setNukiState(6);
                  }, 2000);
                }
              }}
            >
              <MDTypography color="white" textTransform="capitalize">
                {t("unlock").toUpperCase()}
              </MDTypography>
            </MDButton>
          </MDBox>
          <MDBox m={1} key="lock">
            <MDButton
              color="info"
              onClick={() => {
                if (nukiState !== 1) {
                  setNukiState(4);
                  setTimeout(() => {
                    setNukiState(1);
                  }, 2000);
                }
              }}
            >
              <MDTypography color="white" textTransform="capitalize">
                {t("lock").toUpperCase()}
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" textTransform="capitalize" color="text">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ModalDevice14
ModalDevice14.defaultProps = {
  device: {},
  nukiState: 0,
};

// Typechecking props for the ModalDevice14
ModalDevice14.propTypes = {
  device: PropTypes.object,
  nukiState: PropTypes.number,
  setNukiState: PropTypes.func.isRequired,
};

export default ModalDevice14;

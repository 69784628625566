/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import { v4 as uuidv4 } from "uuid";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/DataTable";
import ClientForm from "layouts/clients/components/clientForm";

import { sendCreateClientFirebase, sendRemoveClientFirebase } from "store/actions/clientsActions";

function CellDate({ value }) {
  return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>;
}

const columns = () => {
  const { t } = useTranslation();

  return [
    { Header: t("First Name"), accessor: "firstName", width: 20 },
    { Header: t("Last Name"), accessor: "lastName", width: 20 },
    {
      Header: t("project"),
      accessor: "projectName",
      maxWidth: 20,
    },
    {
      Header: t("apartment"),
      accessor: "apartmentName",
      maxWidth: 20,
    },
    { Header: "Email", accessor: "email", maxWidth: 20 },
    {
      Header: t("startDate"),
      accessor: "startDate",
      Cell: CellDate,
      maxWidth: 10,
    },
    {
      Header: t("endDate"),
      accessor: "endDate",
      Cell: CellDate,
      maxWidth: 10,
    },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 10,
    },
  ];
};

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Clients({
  database,
  clients,
  projects,
  apartments,
  sendRemoveClientFirebaseAct,
  sendCreateClientFirebaseAct,
}) {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uids, setUids] = useState([]);
  const [actionType, setActionType] = useState("");
  const [formatedData, setFormatedData] = useState([]);
  const [clientErrorMessage, setClientErrorMessage] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const remove = (indexes) => {
    const clientsToRemove = [];
    indexes.forEach((client) => {
      const { original } = client;
      clientsToRemove.push(original);
    });
    setActionType("remove");
    setUids(clientsToRemove);
    setOpenConfirm(true);
  };

  const clientSubmit = (data) => {
    switch (actionType) {
      case "edit":
        database.editClient(data);
        setOpen(false);
        break;
      case "add": {
        const uid = uuidv4();
        const clientData = { ...data, uid, mainUserId: database.uid };
        sendCreateClientFirebaseAct(clientData).then(({ result, additionalData }) => {
          if (result === "done") {
            if (additionalData) {
              database.addClient({ ...clientData, ...additionalData });
            } else {
              database.addClient(clientData);
            }
            setActionType("");
            setOpen(false);
          } else {
            switch (result) {
              case "auth/email-already-exists":
                setClientErrorMessage(t("emailAlreadyExists"));
                break;
              case "lock/error-create-code":
                setClientErrorMessage(t("errorCreateCode"));
                break;
              default:
                break;
            }
          }
        });
        break;
      }
      default:
        break;
    }
  };

  const confirm = () => {
    switch (actionType) {
      case "remove":
        sendRemoveClientFirebaseAct(uids[0]).then((result) => {
          if (result === "done") {
            database.removeClients(uids);
            setActionType("");
            setOpenConfirm(false);
          } else {
            setClientErrorMessage(t("unknownError"));
          }
        });
        break;
      default:
        break;
    }
  };

  const add = () => {
    setActionType("add");
    setOpen(true);
  };

  const edit = (indexes) => {
    const clientIndexes = [];
    indexes.forEach((client) => {
      const {
        original: { uid },
      } = client;
      clientIndexes.push(uid);
    });
    setUids(clientIndexes);
    setActionType("edit");
    setOpen(true);
  };

  useEffect(() => {
    const clientsArray = Object.values(clients || {});
    const formatedClients = clientsArray.map((client) => {
      const { projectId, apartmentId } = client;
      return {
        ...client,
        apartmentName: apartments[apartmentId]?.name || apartmentId,
        projectName: projects[projectId]?.name || projectId,
      };
    });
    setFormatedData(formatedClients);
  }, [clients]);

  return (
    <DashboardLayout>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          confirm();
        }}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setClientErrorMessage("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ClientForm
            onSubmit={clientSubmit}
            actionType={actionType}
            uids={uids}
            errorMessage={clientErrorMessage}
          />
        </MDBox>
      </Modal>
      <DashboardNavbar title="clients" route="/clients" />
      <MDBox pt={6} pb={3}>
        <Card>
          <CardTitle title={t("clients")} icon="group" />
          <DataTable
            table={{ columns: columns(projects, apartments), rows: formatedData }}
            canSearch
            canSelect
            canEdit
            canAdd
            canActuate
            add={add}
            edit={edit}
            remove={remove}
            action={(data) => {
              const {
                original: { projectId, apartmentId },
              } = data;
              if (projectId && apartmentId) {
                if (Object.hasOwnProperty.call(apartments, apartmentId)) {
                  const { onnaId } = apartments[apartmentId];
                  navigate(`/projects/${projectId}/apartment/${onnaId}/${apartmentId}`);
                }
              }
            }}
            actionIcon="arrow_forward_ios"
            actionColor="info"
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Clients.defaultProps = {
  clients: {},
  projects: {},
  apartments: {},
};

Clients.propTypes = {
  clients: PropTypes.object,
  projects: PropTypes.object,
  apartments: PropTypes.object,
  database: PropTypes.object.isRequired,
  sendCreateClientFirebaseAct: PropTypes.func.isRequired,
  sendRemoveClientFirebaseAct: PropTypes.func.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.any,
};

const mapStateToProps = (state) => ({
  clients: state?.clientsReducer,
  projects: state?.projectsReducer,
  apartments: state?.apartmentsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  sendCreateClientFirebaseAct: (clientData) =>
    new Promise((resolve, reject) => {
      dispatch(sendCreateClientFirebase({ clientData, resolve, reject }));
    }),
  sendRemoveClientFirebaseAct: (clientData) =>
    new Promise((resolve, reject) => {
      dispatch(sendRemoveClientFirebase({ clientData, resolve, reject }));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);

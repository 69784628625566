/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/DataTable";
import ProjectForm from "layouts/pages/projectForm";
import { newAlertMessage } from "store/actions/globalAlertActions";

function CellDate({ value }) {
  return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>;
}

const columns = () => {
  const { t } = useTranslation();

  return [
    { Header: t("name"), accessor: "name", width: 20 },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 100,
    },
  ];
};

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Projects({ database, projects, newAlertMessageAct }) {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uids, setUids] = useState([]);
  const [actionType, setActionType] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const remove = (indexes) => {
    const projectIndexes = [];
    indexes.forEach((project) => {
      const {
        original: { uid },
      } = project;
      projectIndexes.push(uid);
    });
    const { onnaDevices, apartments } = projects[projectIndexes[0]];
    if (onnaDevices && apartments) {
      if (Object.keys(onnaDevices).length > 0 || Object.keys(apartments).length > 0) {
        newAlertMessageAct({
          type: "error",
          title: t("project"),
          content: t("removeNotEmpty"),
        });
      } else {
        setActionType("remove");
        setUids(projectIndexes);
        setOpenConfirm(true);
      }
    } else {
      setActionType("remove");
      setUids(projectIndexes);
      setOpenConfirm(true);
    }
  };

  const projectSubmit = (data) => {
    switch (actionType) {
      case "edit":
        database.editProject(data);
        setOpen(false);
        break;
      case "add":
        database.addProject(data);
        setOpen(false);
        break;
      default:
        break;
    }
  };

  const confirm = () => {
    switch (actionType) {
      case "remove":
        database.removeProjects(uids);
        setActionType("");
        setOpenConfirm(false);
        break;
      default:
        break;
    }
  };

  const add = () => {
    setActionType("add");
    setOpen(true);
  };

  const edit = (indexes) => {
    const projectIndexes = [];
    indexes.forEach((project) => {
      const {
        original: { uid },
      } = project;
      projectIndexes.push(uid);
    });
    setUids(projectIndexes);
    setActionType("edit");
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          confirm();
        }}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ProjectForm onSubmit={projectSubmit} actionType={actionType} uids={uids} />
        </MDBox>
      </Modal>
      <DashboardNavbar title="projects" route="/projects" />
      <MDBox pt={6} pb={3}>
        <Card>
          <CardTitle title={t("projects")} icon="apartment" />
          <DataTable
            table={{ columns: columns(), rows: Object.values(projects || {}) }}
            canSearch
            canSelect
            canEdit
            canAdd
            add={add}
            edit={edit}
            remove={remove}
            canActuate
            actionIcon="arrow_forward_ios"
            actionColor="info"
            action={(row) => {
              const { original } = row;
              const uid = original?.uid;
              if (uid) {
                navigate(`/projects/${uid}`);
              }
            }}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Projects.defaultProps = {
  projects: {},
};

Projects.propTypes = {
  projects: PropTypes.object,
  database: PropTypes.object.isRequired,
  newAlertMessageAct: PropTypes.func.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.any,
};

const mapStateToProps = (state) => ({
  projects: state?.projectsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  newAlertMessageAct: (data) => dispatch(newAlertMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);

import constants from "../constants";

const { UPDATE_APARTMENTS, REMOVE_APARTMENTS } = constants;

export const updateApartments = (data) => ({
  type: UPDATE_APARTMENTS,
  data,
});

export const removeApartments = (data) => ({
  type: REMOVE_APARTMENTS,
  data,
});

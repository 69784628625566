import constants from "../constants";

const {
  UPDATE_SCHEDULES_STORE,
  SAVE_SCHEDULES_STORE,
  SEND_NEW_SCHEDULE_SERVER,
  SEND_EDIT_SCHEDULE_SERVER,
  SEND_REMOVE_SCHEDULE_SERVER,
  SEND_ENABLE_SCHEDULES_SERVER,
} = constants;

export const updateSchedulesFromServer = (data) => ({
  type: UPDATE_SCHEDULES_STORE,
  data,
});

export const saveSchedulesFromServer = (data) => ({
  type: SAVE_SCHEDULES_STORE,
  data,
});

export const sendNewScheduleServer = (data) => ({
  type: SEND_NEW_SCHEDULE_SERVER,
  data,
});

export const sendEnableSchedulesServer = (data) => ({
  type: SEND_ENABLE_SCHEDULES_SERVER,
  data,
});

export const sendEditScheduleServer = (data) => ({
  type: SEND_EDIT_SCHEDULE_SERVER,
  data,
});

export const sendRemoveScheduleServer = (data) => ({
  type: SEND_REMOVE_SCHEDULE_SERVER,
  data,
});

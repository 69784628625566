import constants from "../constants";

const { UPDATE_APARTMENTS, REMOVE_APARTMENTS } = constants;

const initialState = {};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_APARTMENTS: {
      return { ...action.data };
    }
    case REMOVE_APARTMENTS: {
      return {};
    }
    default:
      return state;
  }
};

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormSelector from "components/FormSelector";

import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "components/FormField";

// Data
import selectData from "layouts/account/components/BasicInfo/data/selectData";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "address",
    "city",
    "postcode",
    "language",
    "phoneNumber",
    "company",
    "vatNumber",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

function BasicInfo({ handleSubmit, pristine, onSubmit }) {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">{t("Basic Info")}</MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field name="firstName" component={FormField} label={t("First Name")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="lastName" component={FormField} label={t("Last Name")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="email" component={FormField} label="Email" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="address" component={FormField} label={t("Address")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="city" component={FormField} label={t("City")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="postcode" component={FormField} label={t("Postcode")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="phoneNumber" component={FormField} label={t("Phone Number")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="company" component={FormField} label={t("Company")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="vatNumber" component={FormField} label={t("VAT Number")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="language"
                values={selectData.languageValues}
                names={selectData.languageNames}
                component={FormSelector}
                label={t("Language")}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
              <MDButton
                type="submit"
                variant="gradient"
                color="dark"
                size="small"
                disabled={pristine}
              >
                {t("Save")}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}

BasicInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  initialValues: props.user,
  user: state.mainUserReducer,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "BasicInfo", // a unique identifier for this form
    enableReinitialize: true,
    validate,
  })(BasicInfo)
);

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import HeaderClient from "layouts/account/components/HeaderClient";
import LogoutAccount from "layouts/account/components/LogoutAccount";

import { useTranslation } from "react-i18next";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

function Settings({ user }) {
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar title="account" isClient />
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HeaderClient t={t} user={user} isClient />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <LogoutAccount />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

Settings.defaultProps = {
  user: {
    uid: "",
  },
};

Settings.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  initialValues: state.mainUserReducer,
  user: state.mainUserReducer,
});

export default connect(mapStateToProps)(Settings);

import constants from "../constants";

const {
  INIT_INTERCOMS_SERVER,
  UPDATE_INTERCOMS_STORE,
  SEND_NEW_CALL_STATE_SERVER,
  SEND_NEW_VOLUMES_SERVER,
  SEND_TEST_AUDIO_SERVER,
} = constants;

export const initIntercomsServer = (data) => ({
  type: INIT_INTERCOMS_SERVER,
  data,
});

export const updateIntercomsStore = (data) => ({
  type: UPDATE_INTERCOMS_STORE,
  data,
});

export const sendNewCallStateServer = (data) => ({
  type: SEND_NEW_CALL_STATE_SERVER,
  data,
});

export const sendNewVolumesServer = (data) => ({
  type: SEND_NEW_VOLUMES_SERVER,
  data,
});

export const sendTestAudioServer = () => ({
  type: SEND_TEST_AUDIO_SERVER,
});

/**
 * Thermostat
 */

// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// @mui material components

import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";

function AccessControl(props) {
  const { lockDetail, setLockState, lockState, errorMessage } = props;
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <MDBox>
              <Icon fontSize="large">key</Icon>
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="body2" color="text" align="center">
                {lockDetail?.electricQuantity ? `${lockDetail?.electricQuantity}%` : "?"}
              </MDTypography>
              <Icons
                name={lockDetail?.electricQuantity > 50 ? "Battery70" : "Battery10"}
                color={lockDetail?.electricQuantity > 50 ? "green" : "red"}
                size={25}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox lineHeight={1} display="flex" justifyContent="space-around">
          <MDTypography variant="h2" ml={2} color="text" align="center" textTransform="capitalize">
            {lockDetail?.lockAlias?.toUpperCase() || "?"}
          </MDTypography>
        </MDBox>
        <MDBox mt={1} display="flex" alignItems="center" flexDirection="column" lineHeight={1}>
          <MDBox m={1} key="unlock">
            <MDButton
              color="info"
              onClick={() => {
                setLockState("unlock");
              }}
            >
              <MDTypography color="white" textTransform="capitalize">
                {lockState === "locked" ? t("unlock").toUpperCase() : t("unlocking").toUpperCase()}
              </MDTypography>
            </MDButton>
          </MDBox>
          {errorMessage && (
            <MDTypography color="error" variant="body2">
              {errorMessage}
            </MDTypography>
          )}
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" textTransform="capitalize" color="text">
            {lockDetail?.lockName?.toUpperCase() || t("unknown")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of AccessControl
AccessControl.defaultProps = {
  lockDetail: {
    name: "unknown",
  },
};

// Typechecking props for the AccessControl
AccessControl.propTypes = {
  lockDetail: PropTypes.object,
  lockState: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setLockState: PropTypes.func.isRequired,
};

export default AccessControl;

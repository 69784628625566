import constants from "../constants";

const { UPDATE_ONNA_DEVICES, REMOVE_ONNA_DEVICES, UPDATE_ONNA_DEVICES_STATE } = constants;

const initialState = {};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_ONNA_DEVICES: {
      return { ...action.data };
    }
    case UPDATE_ONNA_DEVICES_STATE: {
      const newData = {};
      action.data.forEach((onnaDevice) => {
        if (onnaDevice) {
          const { onnaId } = onnaDevice;
          if (state[onnaId]) {
            newData[onnaId] = { ...state[onnaId], ...onnaDevice };
          }
        }
      });
      return { ...state, ...newData };
    }
    case REMOVE_ONNA_DEVICES: {
      return {};
    }
    default:
      return state;
  }
};

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormSwitch from "components/FormSwitch";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";

// Setting pages components
import TableCell from "layouts/account/components/TableCell";

function Notifications({ handleSubmit, pristine, onSubmit }) {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card id="notifications">
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{t("Notifications")}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDBox minWidth="auto">
            <Table sx={{ minWidth: "36rem" }}>
              <MDBox component="thead">
                <TableRow>
                  <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                    {t("Type")}
                  </TableCell>
                  <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                    Email
                  </TableCell>
                </TableRow>
              </MDBox>
              <TableBody>
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t("Alerts")}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t("Notify technical alerts comming from sensors")}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Field name="alerts" component={FormSwitch} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t("Communication")}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t("Notify communication errors with Onna BTR devices")}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Field name="communication" component={FormSwitch} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  size="small"
                  disabled={pristine}
                >
                  {t("Save")}
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </form>
  );
}

Notifications.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  initialValues: props.user,
  user: state.mainUserReducer,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "BasicInfo", // a unique identifier for this form
    enableReinitialize: true,
  })(Notifications)
);

import constants from "../constants";

const {
  UPDATE_RESERVATIONS,
  REMOVE_RESERVATIONS,
  UPDATE_RESERVATION_EVENTS,
  REMOVE_RESERVATION_EVENTS,
  REMOVE_RESERVATION_EVENT,
  ADD_RESERVATION_EVENT,
  EDIT_RESERVATION_EVENT,
} = constants;

export const updateReservations = (data) => ({
  type: UPDATE_RESERVATIONS,
  data,
});

export const removeReservations = (data) => ({
  type: REMOVE_RESERVATIONS,
  data,
});

export const updateReservationEvents = (data) => ({
  type: UPDATE_RESERVATION_EVENTS,
  data,
});

export const removeReservationEvents = (data) => ({
  type: REMOVE_RESERVATION_EVENTS,
  data,
});

export const removeReservationEventStore = (data) => ({
  type: REMOVE_RESERVATION_EVENT,
  data,
});

export const addReservationEventStore = (data) => ({
  type: ADD_RESERVATION_EVENT,
  data,
});

export const editReservationEventStore = (data) => ({
  type: EDIT_RESERVATION_EVENT,
  data,
});

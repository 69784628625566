import constants from "../constants";

const { NEW_ALERT_MESSAGE } = constants;

const initialState = {
  type: "",
  icon: "star",
  title: "Onna Living",
  content: "",
  dateTime: "any",
};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case NEW_ALERT_MESSAGE:
      return { ...action.data };
    default:
      return state;
  }
};

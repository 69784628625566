/**
 * Dimming Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSlider from "components/MDSlider";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

const { grey } = colors;

function ModalDevice5({ device, sendValueServer, deviceState }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { moveAddress, name, value, splitMove, upAddress, downAddress } = device;

  const deviceStateIcon = () => {
    if (value) {
      // eslint-disable-next-line no-nested-ternary
      return deviceState === 100
        ? "WindowShutter"
        : deviceState === 0
        ? "WindowShutterOpen"
        : "WindowShutterHalf";
    }
    return deviceState === 1 ? "WindowShutter" : "WindowShutterOpen";
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons name={deviceStateIcon()} size={40} color={darkMode ? "white" : "black"} />
          <MDBox display="flex">
            <MDButton
              style={{
                padding: 0,
                marginRight: 8,
                backgroundColor: grey[300],
              }}
              onClick={() => {
                if (!splitMove) {
                  sendValueServer(moveAddress, {
                    id: moveAddress,
                    value: 0,
                  });
                } else if (upAddress) {
                  sendValueServer(upAddress, {
                    id: upAddress,
                    value: 1,
                  });
                }
              }}
              iconOnly
            >
              <Icons name="ChevronUp" size={60} />
            </MDButton>
            <MDButton
              style={{
                padding: 0,
                backgroundColor: grey[300],
              }}
              onClick={() => {
                if (!splitMove) {
                  sendValueServer(moveAddress, {
                    id: moveAddress,
                    value: 1,
                  });
                } else if (downAddress) {
                  sendValueServer(downAddress, {
                    id: downAddress,
                    value: 1,
                  });
                }
              }}
              iconOnly
            >
              <Icons name="ChevronDown" size={60} />
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={1} mx={2}>
          <MDSlider
            onChange={(lastValue) => {
              sendValueServer(device.valueAddress, {
                id: device.valueAddress,
                value: lastValue,
              });
            }}
            value={deviceState}
            color="error"
            aria-label="Default"
            valueLabelDisplay="auto"
          />
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography>{`${Math.round(deviceState)}%`}</MDTypography>
          <MDTypography variant="body2" textTransform="capitalize">
            {name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ModalDevice5
ModalDevice5.defaultProps = {
  device: {},
  deviceState: 0,
};

// Typechecking props for the ModalDevice5
ModalDevice5.propTypes = {
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(ModalDevice5, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

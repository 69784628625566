/**
 * Dimming Lamp
 */

// prop-types is a library for typechecking of props
import React, { useState, memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ModalDevice2 from "layouts/apartment/Devices/DeviceModals/ModalDevice2";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function CardDevice2({ color, device, sendValueServer, deviceState }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ModalDevice2
            device={device}
            sendValueServer={sendValueServer}
            deviceState={deviceState}
          />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        bgColor={deviceState ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => {
          setOpen(true);
        }}
        sx={({ palette: { background } }) => ({
          background: darkMode && !deviceState && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons
            name={deviceState ? "LightbulbOnOutline" : "LightbulbOutline"}
            size={40}
            color={darkMode ? "white" : "black"}
          />
          <MDBox
            mt={-0.5}
            mr={-1.5}
            onClick={(event) => {
              event.stopPropagation();
              sendValueServer(device.onOffAddress, {
                id: device.onOffAddress,
                value: deviceState ? 0 : 1,
              });
            }}
          >
            <Switch checked={!!deviceState} />
          </MDBox>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography color={deviceState ? "white" : "text"}>{`${Math.round(
            deviceState
          )}%`}</MDTypography>
          <MDTypography
            variant="body2"
            color={deviceState ? "white" : "text"}
            textTransform="capitalize"
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice2
CardDevice2.defaultProps = {
  color: "info",
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice2
CardDevice2.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(CardDevice2, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

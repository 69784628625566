/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { useState } from "react";

// @mui material components
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Apartments from "layouts/onnaDevice/components/apartments";
import OnnaDeviceInfo from "layouts/onnaDevice/components/onnaDeviceInfo";
import Footer from "examples/Footer";

import { initSocket, endSocket } from "store/actions/socketActions";
import { sendUpdateApp } from "store/actions/settingsActions";

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function OnnaDevice({
  database,
  apartments,
  settings,
  systemState,
  project,
  projectId,
  onnaId,
  token,
  sendInitSocket,
  onnaDevice,
  sendEndSocket,
  sendUpdateAppAction,
  uid,
}) {
  const [openConfirmApartments, setOpenConfirmApartments] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [newImportApartments, setNewImportAparments] = useState(false);

  useEffect(() => {
    sendInitSocket({ onnaId, userId: uid, userName: "cloud", token });
    return () => {
      sendEndSocket([]);
    };
  }, [onnaId]);

  const handleImportApartments = (data) => {
    const apartmentsToImport = data.map((apartment) => {
      const { original } = apartment;
      const { nodeId, name } = original;
      return {
        apartmentId: `${onnaId}@${nodeId}`,
        onnaId,
        name,
      };
    });
    setNewImportAparments(apartmentsToImport);
    setOpenConfirmApartments(true);
  };

  const confirmApartments = () => {
    database.importApartments(newImportApartments, projectId);
  };

  const confirmUpdate = () => {
    sendUpdateAppAction("227520");
  };

  return (
    <DashboardLayout>
      <AlertDialog
        open={openConfirmApartments}
        handleClose={() => {
          setOpenConfirmApartments(false);
        }}
        handleAgree={() => {
          setOpenConfirmApartments(false);
          confirmApartments();
        }}
      />
      <AlertDialog
        open={openConfirmUpdate}
        handleClose={() => {
          setOpenConfirmUpdate(false);
        }}
        handleAgree={() => {
          setOpenConfirmUpdate(false);
          confirmUpdate();
        }}
      />
      <DashboardNavbar
        title={onnaDevice?.name}
        route={`/projects/${project?.name}/${onnaDevice?.name}`}
        firstLink="/projects/"
        secondLink={`/projects/${projectId}`}
      />
      <MDBox pt={6} pb={3}>
        <Apartments
          setOpenConfirm={setOpenConfirmApartments}
          apartments={apartments}
          handleImportApartments={handleImportApartments}
        />
      </MDBox>
      <MDBox pt={6} pb={3}>
        <OnnaDeviceInfo
          settings={settings}
          systemState={systemState}
          sendUpdateAppAction={setOpenConfirmUpdate}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

OnnaDevice.defaultProps = {
  apartments: [],
  onnaDevice: {},
  settings: {},
  systemState: {
    internetConnectionState: false,
    knxConnectionState: false,
  },
  projectId: "",
  project: {
    name: "",
  },
  onnaId: "",
  uid: "",
  token: "",
};

OnnaDevice.propTypes = {
  database: PropTypes.object.isRequired,
  apartments: PropTypes.array,
  settings: PropTypes.object,
  systemState: PropTypes.object,
  onnaId: PropTypes.string,
  uid: PropTypes.string,
  token: PropTypes.string,
  projectId: PropTypes.string,
  sendInitSocket: PropTypes.func.isRequired,
  sendEndSocket: PropTypes.func.isRequired,
  sendUpdateAppAction: PropTypes.func.isRequired,
  onnaDevice: PropTypes.object,
  project: PropTypes.object,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

const mapStateToProps = (state, { onnaId, projectId }) => {
  const {
    configurationReducer,
    settingsReducer,
    systemStateReducer,
    onnaDevicesReducer,
    projectsReducer,
    mainUserReducer,
  } = state;
  const { uid, token } = mainUserReducer;
  return {
    apartments: configurationReducer,
    settings: settingsReducer,
    onnaDevice: onnaDevicesReducer[onnaId],
    systemState: systemStateReducer,
    onnaId,
    uid,
    token,
    projectId,
    project: projectsReducer[projectId],
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendInitSocket: (data) => {
    dispatch(initSocket(data));
  },
  sendEndSocket: () => {
    dispatch(endSocket());
  },
  sendUpdateAppAction: (data) => {
    dispatch(sendUpdateApp(data));
  },
});

const ProjectWithConnect = connect(mapStateToProps, mapDispatchToProps)(OnnaDevice);

function ProjectWrapper(props) {
  const { onnaId, projectId } = useParams();
  return <ProjectWithConnect onnaId={onnaId} projectId={projectId} {...props} />;
}

export default ProjectWrapper;

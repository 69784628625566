import constants from "../constants";

const {
  UPDATE_CLIENTS,
  REMOVE_CLIENTS,
  REMOVE_CLIENT_FIREBASE,
  CREATE_CLIENT_FIREBASE,
  CREATE_MAIN_USER,
  CHECK_CAPTCHA,
} = constants;

export const updateClients = (data) => ({
  type: UPDATE_CLIENTS,
  data,
});

export const removeClients = (data) => ({
  type: REMOVE_CLIENTS,
  data,
});

export const sendRemoveClientFirebase = (data) => ({
  type: REMOVE_CLIENT_FIREBASE,
  data,
});

export const sendCreateClientFirebase = (data) => ({
  type: CREATE_CLIENT_FIREBASE,
  data,
});

export const sendCreateMainUser = (data) => ({
  type: CREATE_MAIN_USER,
  data,
});

export const sendCheckCaptcha = (data) => ({
  type: CHECK_CAPTCHA,
  data,
});

import constants from "../constants";

const { SET_CLOUD_APIS_APARTMENT } = constants;

const initialState = {};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type, data } = action;
  switch (type) {
    case SET_CLOUD_APIS_APARTMENT:
      return {
        ...data,
      };
    default:
      return state;
  }
};

import constants from "../constants";

const {
  UPDATE_MACROS_STORE,
  SAVE_MACROS_STORE,
  SEND_NEW_MACRO_SERVER,
  SEND_EDIT_MACRO_SERVER,
  SEND_REMOVE_MACRO_SERVER,
  SEND_EXECUTE_MACRO_SERVER,
} = constants;

// Global
export const updateMacrosFromServer = (data) => ({
  type: UPDATE_MACROS_STORE,
  data,
});

export const saveMacrosFromServer = (data) => ({
  type: SAVE_MACROS_STORE,
  data,
});

// Moment

export const sendNewMacroServer = (data) => ({
  type: SEND_NEW_MACRO_SERVER,
  data,
});

export const sendEditMacroServer = (data) => ({
  type: SEND_EDIT_MACRO_SERVER,
  data,
});

export const sendRemoveMacroServer = (data) => ({
  type: SEND_REMOVE_MACRO_SERVER,
  data,
});

export const sendExecuteMacroServer = (data) => ({
  type: SEND_EXECUTE_MACRO_SERVER,
  data,
});

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useMaterialUIController } from "context";
import Switch from "@mui/material/Switch";

// Settings page components
import MDTypography from "components/MDTypography";
import MDSlider from "components/MDSlider";
import FormCheckbox from "components/FormCheckbox";
import FormTimePicker from "components/FormTimePicker";
import moment from "moment";

// NewUser page components

const validate = (values) => {
  const errors = {};
  const requiredFields = ["deviceId"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

function EventAddEditForm(props) {
  const {
    onSubmit,
    actionType,
    devices,
    deviceNodeId,
    selectedEvent,
    weekDay,
    change,
    scheduled,
    eventTime,
  } = props;

  const { id, value } = selectedEvent;
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const { t } = useTranslation();
  const {
    type,
    value: deviceValue,
    visualizationType,
    iconOn,
    iconOff,
    valueForOn,
    valueForOff,
    minValue = "7",
    maxValue = "40",
    step = "0.5",
    slowStep = "1",
    decimalPlaces,
    sufix,
    fixedValues,
    labelOn,
    labelOff,
    onOffInverted,
    onOff,
  } = deviceToEdit;

  const [onOffValue, setOnOffValue] = useState(
    actionType === "edit" && !Array.isArray(value) ? value : 0
  );
  const [analogValue, setAnalogValue] = useState(
    actionType === "edit" && !Array.isArray(value) ? parseFloat(value) : 0
  );
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    if (actionType === "edit") {
      if (!Array.isArray(value)) {
        setOnOffValue(value);
        setAnalogValue(parseFloat(value));
      } else {
        value.forEach((item) => {
          const { featureType, featureValue } = item;
          switch (featureType) {
            case "setpoint":
              setAnalogValue(featureValue);
              break;
            case "onOff":
              setOnOffValue(featureValue);
              break;
            default:
              break;
          }
        });
      }
    }
  }, [value]);

  useEffect(() => {
    devices.forEach((device) => {
      if (device.nodeId === deviceNodeId) setDeviceToEdit(device);
    });
  }, [deviceNodeId]);

  useEffect(() => {
    devices.forEach((device) => {
      if (device.nodeId === deviceNodeId) setDeviceToEdit(device);
    });
  }, [JSON.stringify(selectedEvent)]);

  const getValueType = () => {
    // Device value
    let auxValue = 0;
    if (type === "device1") auxValue = onOffValue;
    if (type === "device2") auxValue = analogValue;
    if (type === "device5") {
      if (deviceValue) {
        auxValue = analogValue;
      } else {
        auxValue = onOffValue;
      }
    }
    if (type === "device8") {
      auxValue = onOffValue;
    }
    if (type === "device9") {
      auxValue = analogValue;
    }
    if (type === "device10") {
      auxValue = [];
      auxValue.push({
        featureType: "setpoint",
        featureValue: analogValue,
        featureSend: true,
        featureLabel: `${analogValue}ºC`,
      });
      if (onOff)
        auxValue.push({
          featureType: "onOff",
          featureValue: onOffValue,
          featureSend: true,
          featureLabel: `${t(
            // eslint-disable-next-line no-nested-ternary
            onOffInverted
              ? onOffValue
                ? "labelOff"
                : "labelOn"
              : onOffValue
              ? "labelOn"
              : "labelOff"
          ).toUpperCase()}`,
        });
    }
    return auxValue;
  };

  const getValueText = () => {
    let state = 0;
    const customAnalogFixedValue = (newValue) => {
      let msg = t("noMatch").toUpperCase();
      fixedValues.forEach((item) => {
        if (parseFloat(item.value) === Math.round(newValue)) {
          msg = item.label;
        }
      });
      return msg;
    };
    if (type === "device1") {
      state = onOffValue ? t("btnOn").toUpperCase() : t("btnOff").toUpperCase();
    }
    if (type === "device2") {
      state = `${analogValue}%`;
    }
    if (type === "device5") {
      if (value) {
        state = `${analogValue}%`;
      } else {
        state = onOffValue ? t("btnDown").toUpperCase() : t("btnUp").toUpperCase();
      }
    }
    if (type === "device8") {
      if (visualizationType === "label") {
        state =
          onOffValue === parseInt(valueForOn, 10) ? labelOn.toUpperCase() : labelOff.toUpperCase();
      } else {
        state =
          onOffValue === parseInt(valueForOn, 10)
            ? t("btnOn").toUpperCase()
            : t("btnOff").toUpperCase();
      }
    }
    if (type === "device9") {
      if (visualizationType === "fixed") {
        state = customAnalogFixedValue(analogValue).toUpperCase();
      } else {
        state = `${analogValue}${sufix}`;
      }
    }
    if (type === "device10") {
      state = `${analogValue}ºC`;
      state += `, ${t(
        // eslint-disable-next-line no-nested-ternary
        onOffInverted ? (onOffValue ? "labelOff" : "labelOn") : onOffValue ? "labelOn" : "labelOff"
      ).toUpperCase()}`;
    }
    return state;
  };

  const submitData = () => {
    let minute = 0;
    let hour = 0;
    if (moment.isMoment(eventTime)) {
      hour = eventTime.format("HH");
      minute = eventTime.format("m");
      const device = {
        day: "*",
        hour: parseInt(hour, 10),
        id,
        minute: parseInt(minute, 10),
        month: "*",
        scheduled,
        second: "0",
        sunrise: false,
        sunset: false,
        weekDay,
        nodeId: deviceNodeId,
        value: getValueType(),
        valueText: getValueText(),
      };
      onSubmit(device);
    }
  };

  const getShutterIcon = () => {
    const { value: valueConfig } = deviceToEdit;
    if (valueConfig) {
      // eslint-disable-next-line no-nested-ternary
      return analogValue === 100
        ? "WindowShutter"
        : analogValue === 0
        ? "WindowShutterOpen"
        : "WindowShutterHalf";
    }
    return onOffValue === 1 ? "WindowShutter" : "WindowShutterOpen";
  };

  const getIcon = () => {
    switch (type) {
      case "device1":
        return (
          <Icons
            name={onOffValue ? "LightbulbOnOutline" : "LightbulbOutline"}
            size={40}
            color={darkMode ? "white" : "black"}
          />
        );
      case "device2":
        return (
          <Icons
            name={analogValue ? "LightbulbOnOutline" : "LightbulbOutline"}
            size={40}
            color={darkMode ? "white" : "black"}
          />
        );
      case "device5":
        return <Icons name={getShutterIcon()} size={40} color={darkMode ? "white" : "black"} />;
      case "device6":
        return visualizationType === "icon" ? (
          <Icons
            name={onOffValue ? iconOn : iconOff}
            size={40}
            color={darkMode ? "white" : "black"}
          />
        ) : (
          <div />
        );
      case "device8":
        return visualizationType === "icon" ? (
          <Icons
            name={parseInt(valueForOn, 10) === onOffValue ? iconOn : iconOff}
            size={40}
            color={darkMode ? "white" : "black"}
          />
        ) : (
          <div />
        );
      case "device10":
        return <Icons name="Thermometer" size={40} color={darkMode ? "white" : "black"} />;
      default:
        return <div />;
    }
  };

  // const getSlider = () => {};

  const onOffChange = () => {
    switch (type) {
      case "device1":
        setOnOffValue(onOffValue ? 0 : 1);
        break;
      case "device8": {
        let newValue = 0;
        if (onOffValue === parseInt(valueForOn, 10) || onOffValue !== parseInt(valueForOff, 10)) {
          newValue = parseInt(valueForOff, 10);
        } else {
          newValue = parseInt(valueForOn, 10);
        }
        setOnOffValue(newValue);
        break;
      }
      case "device10": {
        setOnOffValue(onOffValue ? 0 : 1);
        break;
      }
      default:
        break;
    }
  };

  const getControls = () => {
    switch (type) {
      case "device1":
        return (
          <Switch
            checked={!!onOffValue}
            onChange={() => {
              onOffChange();
            }}
          />
        );
      case "device8":
        return (
          <Switch
            checked={
              !!(
                onOffValue === parseInt(valueForOn, 10) || onOffValue !== parseInt(valueForOff, 10)
              )
            }
            onChange={() => {
              onOffChange();
            }}
          />
        );
      case "device10":
        return (
          <Switch
            checked={onOffInverted ? !onOffValue : !!onOffValue}
            onChange={() => {
              onOffChange();
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const getValue = () => {
    const labelAnalogValue = () => `${analogValue?.toFixed(decimalPlaces)} ${sufix}`;
    const labelAnalogFixed = () => {
      let msg = "noMatch".toUpperCase();
      for (let i = 0; i < fixedValues.length; i += 1) {
        if (parseInt(fixedValues[i].analogValue, 10) === Math.round(analogValue)) {
          msg = fixedValues[i].label;
        }
      }
      return msg;
    };

    switch (type) {
      case "device2":
        return (
          <MDBox mt={1} mx={2}>
            <MDSlider
              onChange={(newValue) => {
                setAnalogValue(newValue);
              }}
              value={analogValue}
              color="error"
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </MDBox>
        );
      case "device5":
        return deviceValue ? (
          <MDBox mt={1} mx={2}>
            <MDSlider
              onChange={(newValue) => {
                setAnalogValue(newValue);
              }}
              value={analogValue}
              color="error"
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </MDBox>
        ) : (
          <div />
        );
      case "device9":
        return (
          <MDBox minHeight={125}>
            {visualizationType === "fixed" && (
              <Grid container mt={1} mb={2} display="flex" justifyContent="center">
                {fixedValues.map((item) => {
                  const { value: valueToSend, label } = item;
                  return (
                    <MDBox m={1} key={analogValue + label}>
                      <MDButton
                        color="info"
                        onClick={() => {
                          setAnalogValue(valueToSend);
                        }}
                      >
                        <MDTypography color="white" textTransform="capitalize">
                          {label}
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  );
                })}
              </Grid>
            )}
            {visualizationType === "value" && (
              <MDBox mt={1} mx={2}>
                <MDSlider
                  onChange={(newValue) => {
                    setAnalogValue(newValue);
                  }}
                  value={analogValue}
                  min={parseFloat(minValue)}
                  max={parseFloat(maxValue)}
                  step={parseFloat(slowStep)}
                  color="error"
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </MDBox>
            )}
            <MDBox mb={1} lineHeight={1} display="flex" justifyContent="center">
              {visualizationType === "value" && (
                <MDTypography variant="h2">{labelAnalogValue()}</MDTypography>
              )}
              {visualizationType === "fixed" && (
                <MDTypography variant="h2">{labelAnalogFixed()}</MDTypography>
              )}
            </MDBox>
          </MDBox>
        );
      case "device10":
        return (
          <MDBox minHeight={125}>
            <MDBox mt={1} mx={2}>
              <MDSlider
                onChange={(newValue) => {
                  setAnalogValue(newValue);
                }}
                value={analogValue}
                color="error"
                aria-label="Default"
                valueLabelDisplay="auto"
                min={parseFloat(minValue)}
                max={parseFloat(maxValue)}
                step={parseFloat(step)}
              />
            </MDBox>
            <MDBox mb={1} lineHeight={1} display="flex" justifyContent="center">
              <MDTypography variant="h2">{`${analogValue.toFixed(1)}ºC`}</MDTypography>
            </MDBox>
          </MDBox>
        );
      default:
        return <div />;
    }
  };

  const getDevice = () => (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <MDBox display="flex" sx={{ minWidth: 0 }}>
            {getIcon()}
          </MDBox>
          <MDBox
            mt={-0.5}
            mr={-1}
            sx={{ minWidth: 0 }}
            display="flex"
            justifyContent="flex-end"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {getControls()}
          </MDBox>
        </MDBox>
        {getValue()}
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" textTransform="capitalize">
            {deviceToEdit?.name?.toUpperCase() || ""}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );

  const weekDayPicker = () => (
    <Grid container display="flex" justifyContent="center">
      {t("weekDays", { returnObjects: true }).map((element, index) => (
        <Grid item m={0.5} key={element}>
          <MDButton
            color={weekDay.includes(index + 1) ? "info" : "dark"}
            onClick={() => {
              // Remove day
              if (weekDay.includes(index + 1)) {
                change(
                  "weekDay",
                  weekDay.filter((e) => e !== index + 1)
                );
              }
              // Add day
              else {
                const newWeekDays = [...weekDay, index + 1];
                change("weekDay", [...newWeekDays.sort()]);
              }
            }}
          >
            <MDTypography color="white" textTransform="capitalize">
              {element?.toUpperCase()}
            </MDTypography>
          </MDButton>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <MDBox py={3} width="100%">
      <form onSubmit={() => {}}>
        <Card id="basic-info" sx={{ overflow: "visible", width: "100%" }}>
          <MDBox
            height="4rem"
            bgColor="info"
            variant="gradient"
            coloredShadow="info"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            mt={-3}
            mx={10}
            px={2}
          >
            {t(actionType)} {t("device")}
          </MDBox>
          <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
            <Grid item xs={12} sm={12}>
              <Grid container px={2}>
                <Grid item mt={2} xs={12} sm={12}>
                  <Grid
                    item
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={12}>
                      {weekDayPicker()}
                    </Grid>
                    <Grid item xs={12} sm={6} mx={1}>
                      <Field name="eventTime" component={FormTimePicker} label={t("time")} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {getDevice()}
                    </Grid>
                    <Grid item>
                      <Field
                        name="scheduled"
                        component={FormCheckbox}
                        label={t("scheduled").toUpperCase()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="center" m={2} xs={12}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      {t("Save")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </form>
    </MDBox>
  );
}

EventAddEditForm.defaultProps = {
  devices: [],
  weekDay: [],
  eventTime: "",
  selectedEvent: {},
  scheduled: false,
};

EventAddEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  devices: PropTypes.array,
  weekDay: PropTypes.array,
  scheduled: PropTypes.bool,
  deviceNodeId: PropTypes.string.isRequired,
  eventTime: PropTypes.any,
  selectedEvent: PropTypes.object,
};

const selector = formValueSelector("EventAddEditForm");

const mapStateToProps = (state, props) => {
  const { selectedEvent } = props;
  const { hour, minute } = selectedEvent;
  const time = moment();
  if (hour && minute) {
    time.hour(hour);
    time.minute(minute);
  }
  return {
    weekDay: selector(state, "weekDay"),
    eventTime: selector(state, "eventTime"),
    scheduled: selector(state, "scheduled"),
    initialValues: {
      scheduled: true,
      ...selectedEvent,
      eventTime: time,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "EventAddEditForm", // a unique identifier for this form
    validate,
  })(EventAddEditForm)
);

/**
 * Consumption
 */

// prop-types is a library for typechecking of props
import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useTranslation } from "react-i18next";

import Chart from "components/Chart";

function CardDevice12(props) {
  const { device, valueInput, powerInput, energyInput, trendsHistory } = props;
  const { t } = useTranslation();
  const {
    consumptionControlType,
    valueAddress,
    decimalPlaces,
    sufix,
    powerDecimalPlaces,
    powerSufix,
    energyAddress,
    energyDecimalPlaces,
    energySufix,
  } = device;

  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const getTrendsHistory = () => {
    let lastDayHistory = 0;
    let lastMonthHistory = 0;
    if (consumptionControlType === "ELECTRICENERGY") {
      const lastDay = trendsHistory[energyAddress]?.lastDay;
      const lastMonth = trendsHistory[energyAddress]?.lastMonth;
      if (lastDay) lastDayHistory = lastDay;
      if (lastMonth) lastMonthHistory = lastMonth;
    } else {
      const lastDay = trendsHistory[valueAddress]?.lastDay;
      const lastMonth = trendsHistory[valueAddress]?.lastMonth;
      if (lastDay) lastDayHistory = lastDay;
      if (lastMonth) lastMonthHistory = lastMonth;
    }
    return {
      lastDayHistory,
      lastMonthHistory,
    };
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <Chart device={device} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" sx={{ minWidth: 150 }}>
            <Icons name="Chart" size={35} color={darkMode ? "white" : "black"} />
          </MDBox>
        </MDBox>
        {consumptionControlType === "ELECTRICENERGY" && (
          <MDBox lineHeight={1} display="flex" justifyContent="space-around">
            <MDBox lineHeight={1} justifyContent="center">
              <MDTypography variant="body2" color="text" align="center">
                {t("power")}
              </MDTypography>
              <MDTypography variant="h4" color="text" align="center">{`${powerInput.toFixed(
                powerDecimalPlaces
              )}${powerSufix}`}</MDTypography>
            </MDBox>
            <MDBox lineHeight={1} justifyContent="center">
              <MDTypography variant="body2" color="text" align="center">
                {t("day")}
              </MDTypography>
              <MDTypography variant="h4" color="text" align="center">{`${(
                energyInput - getTrendsHistory().lastDayHistory
              )?.toFixed(energyDecimalPlaces)} ${energySufix}`}</MDTypography>
            </MDBox>
            <MDBox lineHeight={1} justifyContent="center">
              <MDTypography variant="body2" color="text" align="center">
                {t("month")}
              </MDTypography>
              <MDTypography variant="h4" color="text" align="center">{`${(
                energyInput - getTrendsHistory().lastMonthHistory
              )?.toFixed(energyDecimalPlaces)} ${energySufix}`}</MDTypography>
            </MDBox>
          </MDBox>
        )}
        {consumptionControlType !== "ELECTRICENERGY" && (
          <MDBox lineHeight={1} display="flex" justifyContent="space-around">
            <MDBox lineHeight={1} justifyContent="center">
              <MDTypography variant="body2" color="text" align="center">
                {t("day")}
              </MDTypography>
              <MDTypography variant="h4" color="text" align="center">{`${(
                valueInput - getTrendsHistory().lastDayHistory
              )?.toFixed(decimalPlaces)} ${sufix}`}</MDTypography>
            </MDBox>
            <MDBox lineHeight={1} justifyContent="center">
              <MDTypography variant="body2" color="text" align="center">
                {t("month")}
              </MDTypography>
              <MDTypography variant="h4" color="text" align="center">{`${(
                valueInput - getTrendsHistory().lastMonthHistory
              )?.toFixed(decimalPlaces)} ${sufix}`}</MDTypography>
            </MDBox>
          </MDBox>
        )}
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" textTransform="capitalize" color="text">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice12
CardDevice12.defaultProps = {
  device: {},
  trendsHistory: {},
  valueInput: 0,
  powerInput: 0,
  energyInput: 0,
};

// Typechecking props for the CardDevice12
CardDevice12.propTypes = {
  device: PropTypes.object,
  trendsHistory: PropTypes.object,
  valueInput: PropTypes.number,
  powerInput: PropTypes.number,
  energyInput: PropTypes.number,
};

const mapStateToProps = (state, props) => {
  const {
    knxAddressReducer = [],
    trendsReducer: { trendsHistory },
  } = state;
  const { device } = props;
  const addressObject = {};
  const keys = Object.keys(device);
  keys.forEach((key) => {
    if (key.includes("Address") && device[key]) {
      const address = knxAddressReducer.find((foundAddress) => foundAddress.id === device[key]);
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (address) addressObject[newKey] = address.value;
    }
  });

  return {
    ...addressObject,
    trendsHistory,
  };
};

export default connect(mapStateToProps)(
  memo(CardDevice12, (prevProps, nextProps) => {
    const { device } = prevProps;
    const { nodeId: nodeIdPrev } = device;
    const {
      device: { nodeId },
    } = nextProps;
    const test = [];
    const keys = Object.keys(device);
    keys.forEach((key) => {
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (key.includes("Address") && device[key]) {
        test.push(prevProps[newKey] !== nextProps[key]);
      }
    });
    return test.indexOf(true) === -1 && nodeIdPrev === nodeId;
  })
);

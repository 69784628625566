import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

function NewSlider({ onChange, value, ...rest }) {
  const [lastValue, setLastValue] = useState(value);
  useEffect(() => {
    setLastValue(value);
  }, [value]);

  return (
    <Slider
      onChange={(event) => {
        setLastValue(event.target.value);
      }}
      onChangeCommitted={() => {
        onChange(lastValue);
      }}
      value={lastValue}
      {...rest}
    />
  );
}

NewSlider.defaultProps = {
  onChange: () => {},
  value: 0,
};

// Typechecking props for the ModalDevice2
NewSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default styled(NewSlider)(() => ({
  color: "#000",
  height: 40,
  "& .MuiSlider-track": {
    background: "#000",
    height: 5,
    border: "none",
  },
  "& .MuiSlider-rail": {
    height: 5,
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "white",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

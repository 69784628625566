import constants from "../constants";

const { UPDATE_NOTIFICATIONS, REMOVE_NOTIFICATIONS, UPDATE_BADGE_NOTIFICATIONS } = constants;

const initialState = {
  notifications: {},
  badgeNumber: 0,
};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_NOTIFICATIONS:
      return { ...state, notifications: { ...action.data } };
    case REMOVE_NOTIFICATIONS:
      return { badgeNumber: 0, notifications: {} };
    case UPDATE_BADGE_NOTIFICATIONS:
      return { ...state, badgeNumber: action.data };
    default:
      return state;
  }
};

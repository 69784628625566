import constants from "../constants";

const { INIT_CONFIGURATION_STORE } = constants;
const initialState = [];
export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  const { configuration } = action;
  switch (type) {
    case INIT_CONFIGURATION_STORE:
      return configuration;
    default:
      return state;
  }
};

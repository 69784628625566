/* eslint-disable camelcase */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "components/FormField";
import MDAvatar from "components/MDAvatar";
import { newAlertMessage } from "store/actions/globalAlertActions";
import { useState } from "react";
import { uploadMainUserImage } from "store/actions/mainUserActions";

// Data

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "titleWelcome_en",
    "titleWelcome_es",
    "titleWelcome_fr",
    "titleRooms_en",
    "titleRooms_es",
    "titleRooms_fr",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

function CustomApp({
  handleSubmit,
  pristine,
  onSubmit,
  user,
  actNewAlertMessage,
  actSendMainUserImage,
}) {
  const [imageData, setImageData] = useState();
  const { uid } = user;
  const { t } = useTranslation();
  const { imageUrl = `https://onnaliving.com/userimagefiles/${uid}.png` } = user;

  const editDataAndSubmit = (data) => {
    const {
      titleWelcome_en,
      titleWelcome_es,
      titleWelcome_fr,
      titleRooms_en,
      titleRooms_es,
      titleRooms_fr,
    } = data;
    const { customApp } = user;

    onSubmit({
      ...user,
      customApp: {
        ...customApp,
        customLang: {
          en: {
            titleWelcome: titleWelcome_en,
            titleRooms: titleRooms_en,
          },
          es: {
            titleWelcome: titleWelcome_es,
            titleRooms: titleRooms_es,
          },
          fr: {
            titleWelcome: titleWelcome_fr,
            titleRooms: titleRooms_fr,
          },
        },
      },
    });
  };

  const submitImage = () => {
    onSubmit({
      ...user,
      imageUrl: `https://onnaliving.com/userimagefiles/${uid}.png?${Date.now()}`,
    });
  };

  const onChangeImage = (data) => {
    if (data.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const { width, height } = image;
          if (width === 256 && height === 256) {
            setImageData(reader.result);
          } else {
            actNewAlertMessage({
              type: "warning",
              title: "image",
              content: "wrongSize",
            });
          }
        };
      });
      reader.readAsDataURL(data.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit(editDataAndSubmit)}>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">{t("Custom App")}</MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Field name="titleWelcome_en" component={FormField} label={t("Welcome EN")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="titleRooms_en" component={FormField} label={t("Title Home EN")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="titleWelcome_es" component={FormField} label={t("Welcome ES")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="titleRooms_es" component={FormField} label={t("Title Home ES")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="titleWelcome_fr" component={FormField} label={t("Welcome FR")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="titleRooms_fr" component={FormField} label={t("Title Home FR")} />
            </Grid>
            <Grid item display="flex" flexDirection="column" alignItems="center" xs={12} sm={12}>
              <MDBox style={{ position: "relative" }}>
                <input
                  style={{
                    opacity: "0.0",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                  }}
                  id="profile-img-file-input"
                  type="file"
                  accept="image/png"
                  onChange={onChangeImage}
                />
                <MDAvatar
                  style={{ zIndex: 1 }}
                  alt="profile-image"
                  src={imageData || imageUrl}
                  size="xl"
                  shadow="sm"
                  bgColor="light"
                />
              </MDBox>
              <MDBox>
                <MDButton
                  style={{ marginTop: 10 }}
                  size="small"
                  variant="contained"
                  component="label"
                  startIcon={<Icon>upload</Icon>}
                  onClick={() => {
                    actSendMainUserImage({
                      image: imageData,
                      cb: (result) => {
                        if (result) {
                          actNewAlertMessage({
                            type: "success",
                            title: "image",
                            content: `${t("uploadFile")} ${t("Success")}`,
                          });
                          submitImage();
                        } else {
                          actNewAlertMessage({
                            type: "error",
                            title: "image",
                            content: `${t("uploadFile")} ${t("unknownError")}`,
                          });
                        }
                      },
                    });
                  }}
                >
                  {t("uploadFile")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
              <MDButton
                type="submit"
                variant="gradient"
                color="dark"
                size="small"
                disabled={pristine}
              >
                {t("Save")}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}

CustomApp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  actNewAlertMessage: PropTypes.func.isRequired,
  actSendMainUserImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { mainUserReducer } = state;
  const {
    customApp: { customLang },
  } = mainUserReducer;

  const initialValues = {};
  if (customLang !== undefined) {
    const languages = Object.keys(customLang);
    languages.forEach((language) => {
      const languageKeys = Object.keys(customLang[language]);
      languageKeys.forEach((languageKey) => {
        initialValues[`${languageKey}_${language}`] = customLang[language][languageKey];
      });
    });
  }
  return {
    initialValues,
    user: mainUserReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actNewAlertMessage: (message) => dispatch(newAlertMessage(message)),
  actSendMainUserImage: (data) => dispatch(uploadMainUserImage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CustomApp", // a unique identifier for this form
    enableReinitialize: true,
    validate,
  })(CustomApp)
);

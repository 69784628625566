/**
 * Dimming Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSlider from "components/MDSlider";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function ModalDevice2({ color, device, sendValueServer, deviceState }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        bgColor={deviceState ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && !deviceState && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons
            name={deviceState ? "LightbulbOnOutline" : "LightbulbOutline"}
            size={40}
            color={darkMode ? "white" : "black"}
          />

          <MDBox mt={-0.5} mr={-1.5}>
            <Switch
              checked={!!deviceState}
              onChange={() => {
                sendValueServer(device.onOffAddress, {
                  id: device.onOffAddress,
                  value: deviceState ? 0 : 1,
                });
              }}
            />
          </MDBox>
        </MDBox>
        <MDBox mt={1} mx={2}>
          <MDSlider
            onChange={(value) => {
              sendValueServer(device.valueAddress, {
                id: device.valueAddress,
                value,
              });
            }}
            value={deviceState}
            color="error"
            aria-label="Default"
            valueLabelDisplay="auto"
          />
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography color={deviceState ? "white" : "text"}>{`${Math.round(
            deviceState
          )}%`}</MDTypography>
          <MDTypography
            variant="body2"
            color={deviceState ? "white" : "text"}
            textTransform="capitalize"
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ModalDevice2
ModalDevice2.defaultProps = {
  color: "info",
  device: {},
  deviceState: 0,
};

// Typechecking props for the ModalDevice2
ModalDevice2.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(ModalDevice2, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

import constants from "../constants";

const { INIT_CONFIGURATION_SERVER, INIT_CONFIGURATION_STORE } = constants;

// Configuration
export const initConfigurationServer = () => ({
  type: INIT_CONFIGURATION_SERVER,
});

export const initConfigurationStore = (configuration) => ({
  type: INIT_CONFIGURATION_STORE,
  configuration,
});

const {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} = require("@mui/material");
const { default: MDBox } = require("components/MDBox");
const { default: MDButton } = require("components/MDButton");
const { useMaterialUIController } = require("context");
const PropTypes = require("prop-types");
const { useTranslation } = require("react-i18next");

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

export default AlertDialog;

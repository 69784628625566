import constants from "../constants";

const {
  ACCESS_CONTROL_CHECK_CREDENTIALS,
  ACCESS_CONTROL_GET_LOCKS,
  ACCESS_CONTROL_GET_LOCK_DETAIL,
  ACCESS_CONTROL_LOCK_UNLOCK,
  ACCESS_CONTROL_GET_LOCK_PASSWORDS,
  ACCESS_CONTROL_REMOVE_LOCK_PASSWORD,
  ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT,
  ACCESS_CONTROL_LOCK_UNLOCK_CLIENT,
  ACCESS_CONTROL_CREATE_PASSWORD,
} = constants;

export const accessControlCheckCredentials = (data) => ({
  type: ACCESS_CONTROL_CHECK_CREDENTIALS,
  data,
});

export const accessControlGetLocks = (data) => ({
  type: ACCESS_CONTROL_GET_LOCKS,
  data,
});

export const accessControlGetLockPasswords = (data) => ({
  type: ACCESS_CONTROL_GET_LOCK_PASSWORDS,
  data,
});

export const accessControlRemoveLockPassword = (data) => ({
  type: ACCESS_CONTROL_REMOVE_LOCK_PASSWORD,
  data,
});

export const accessControlGetLockDetail = (data) => ({
  type: ACCESS_CONTROL_GET_LOCK_DETAIL,
  data,
});

export const accessControlCreatePassword = (data) => ({
  type: ACCESS_CONTROL_CREATE_PASSWORD,
  data,
});

export const accessControlLockUnlock = (data) => ({
  type: ACCESS_CONTROL_LOCK_UNLOCK,
  data,
});

export const accessControlGetLockDetailClient = (data) => ({
  type: ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT,
  data,
});

export const accessControlLockUnlockClient = (data) => ({
  type: ACCESS_CONTROL_LOCK_UNLOCK_CLIENT,
  data,
});

import constants from "../constants";

const { EDIT_ADDRESS_STORE, INIT_ADDRESSES_STORE } = constants;

export default (state = [], action) => {
  switch (action.type) {
    case EDIT_ADDRESS_STORE:
      return state.map((address) => {
        if (address.id === action.id) {
          return {
            ...address,
            ...action.updates,
          };
        }
        return address;
      });
    case INIT_ADDRESSES_STORE:
      return action.addresses;
    default:
      return state;
  }
};

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import ReservationCalendar from "layouts/reservation/components/reservationCalendar";
import Footer from "examples/Footer";
import ReservationForm from "layouts/reservation/components/reservationForm";
import { removeReservationEvents } from "store/actions/reservationsActions";
import { newAlertMessage } from "store/actions/globalAlertActions";

function Reservation({
  actNewAlertMessage,
  database,
  project,
  projectId,
  reservation,
  reservationId,
  events,
  actRemoveReservationEvents,
}) {
  const [open, setOpen] = useState(false);

  const [event, setEvent] = useState({});
  const [newEvent, setNewEvent] = useState({});
  const [actionType, setActionType] = useState(false);

  const isOverlapping = (eventToCreate) => {
    let isOverlappingState = false;
    events.forEach((existingEvent) => {
      if (existingEvent.id !== eventToCreate.id) {
        if (
          !(
            new Date(existingEvent.start) >= new Date(eventToCreate.end) ||
            new Date(existingEvent.end) <= new Date(eventToCreate.start)
          )
        ) {
          isOverlappingState = true;
        }
      }
    });
    return isOverlappingState;
  };

  const handleDates = (rangeInfo) => {
    actRemoveReservationEvents();
    const { startStr, endStr } = rangeInfo;
    database.getReservationEvents({ reservationId, startDate: startStr, endDate: endStr });
    // this.props.requestEvents(rangeInfo.startStr, rangeInfo.endStr).catch(reportNetworkError);
  };

  const handleDateSelect = (selectInfo) => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    setActionType("add");
    setNewEvent(selectInfo);
    setOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    setActionType("edit");
    setEvent(clickInfo.event.toPlainObject());
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ReservationForm
            reservation={reservation}
            event={event}
            newEvent={newEvent}
            onSubmit={(data) => {
              setEvent({});
              if (!isOverlapping(data)) {
                database.addReservationEvent({ reservationId, eventId: data.id, event: data });
              } else {
                actNewAlertMessage({
                  type: "warning",
                  title: "reservation",
                  content: "isOverlapping",
                });
              }
              setOpen(false);
            }}
            onRemove={(eventId) => {
              setEvent({});
              database.removeReservationEvent({ reservationId, eventId });
              setOpen(false);
            }}
            actionType={actionType}
            projectId={projectId}
          />
        </MDBox>
      </Modal>
      <DashboardNavbar
        title={reservation?.name}
        route={`/projects/${project?.name}/${reservation?.name}`}
        firstLink="/projects/"
        secondLink={`/projects/${projectId}`}
      />
      <MDBox pt={6} pb={3}>
        <ReservationCalendar
          handleDates={handleDates}
          handleDateSelect={handleDateSelect}
          handleEventClick={handleEventClick}
          events={events}
          reservation={reservation}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Reservation.defaultProps = {
  reservation: {},
  projectId: "",
  reservationId: "",
  events: [],
  project: {
    name: "",
  },
};

Reservation.propTypes = {
  actNewAlertMessage: PropTypes.func.isRequired,
  database: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  events: PropTypes.array,
  reservation: PropTypes.object,
  reservationId: PropTypes.string,
  project: PropTypes.object,
  actRemoveReservationEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { uid, projectId }) => {
  const { configurationReducer, reservationsReducer, projectsReducer } = state;
  const { reservations, reservationEvents } = reservationsReducer;

  return {
    apartments: configurationReducer,
    reservation: reservations[uid],
    events: Object.values(reservationEvents || {}),
    reservationId: uid,
    projectId,
    project: projectsReducer[projectId],
  };
};

const mapDispatchToProps = (dispatch) => ({
  actRemoveReservationEvents: () => dispatch(removeReservationEvents()),
  actNewAlertMessage: (data) => dispatch(newAlertMessage(data)),
});

const ProjectWithConnect = connect(mapStateToProps, mapDispatchToProps)(Reservation);

function ProjectWrapper(props) {
  const { uid, projectId } = useParams();
  return <ProjectWithConnect uid={uid} projectId={projectId} {...props} />;
}

export default ProjectWrapper;

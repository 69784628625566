/**
 * On/Off Lamp
 */

// prop-types is a library for typechecking of props
import React, { memo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function CardDevice8({ device, deviceState, sendValueServer }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { valueForOn, valueForOff, labelOn, labelOff, iconOff, iconOn } = device;
  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={2}
        height="100%"
        bgColor="white"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <MDBox>
            {device.visualizationType === "icon" && (
              <Icons
                name={parseInt(valueForOn, 10) === deviceState ? iconOn : iconOff}
                size={40}
                color={darkMode ? "white" : "black"}
              />
            )}
            {device.visualizationType === "label" &&
              (parseInt(valueForOn, 10) === deviceState ? (
                <MDTypography>{`${labelOn}`}</MDTypography>
              ) : (
                <MDTypography>{`${labelOff}`}</MDTypography>
              ))}
          </MDBox>
          <MDBox mt={-0.5} mr={-1.5}>
            <Switch
              checked={
                !!(
                  deviceState === parseInt(valueForOn, 10) ||
                  deviceState !== parseInt(valueForOff, 10)
                )
              }
              onChange={() => {
                let value = 0;
                if (
                  deviceState === parseInt(valueForOn, 10) ||
                  deviceState !== parseInt(valueForOff, 10)
                ) {
                  value = parseInt(valueForOff, 10);
                } else {
                  value = parseInt(valueForOn, 10);
                }
                sendValueServer(device.onOffAddress, {
                  id: device.onOffAddress,
                  value,
                });
              }}
            />
          </MDBox>
        </MDBox>

        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" textTransform="capitalize">
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice8
CardDevice8.defaultProps = {
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice8
CardDevice8.propTypes = {
  device: PropTypes.object,
  deviceState: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

export default memo(CardDevice8, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

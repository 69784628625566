import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import primaryFunction from "sagas/sagas";
import reducers from "./reducers";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const sagaMiddleware = createSagaMiddleware();

const middleware = [];
middleware.push(sagaMiddleware);
const router = routerMiddleware(history);
middleware.push(router);

export const store = createStore(
  rootReducer,
  /* preloadedState, */
  // Specify custom devTools options
  process.env.NODE_ENV === "production"
    ? compose(applyMiddleware(...middleware))
    : compose(composeWithDevTools(applyMiddleware(...middleware)))
);

sagaMiddleware.run(primaryFunction);

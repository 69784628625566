/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import Switch from "@mui/material/Switch";

function FormSwitch({ input, ...custom }) {
  return <Switch checked={input.value} variant="standard" {...input} {...custom} />;
}

// Setting default values for the props of FormSwitch
FormSwitch.defaultProps = {
  input: {
    value: false,
  },
};

// Typechecking props for FormSwitch
FormSwitch.propTypes = {
  input: PropTypes.object,
};

export default FormSwitch;

import constants from "../constants";

const { SAVE_SCHEDULES_STORE, UPDATE_SCHEDULES_STORE } = constants;

const initialState = {
  schedules: {},
};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case SAVE_SCHEDULES_STORE: {
      return { schedules: action.data };
    }
    case UPDATE_SCHEDULES_STORE: {
      const { schedules, apartmentId } = action.data;
      if (apartmentId) {
        return {
          schedules: {
            ...state.schedules,
            [apartmentId]: schedules,
          },
        };
      }
      return { schedules };
    }
    default:
      return state;
  }
};

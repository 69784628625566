import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

function ProtectedRouteMainUser({ user, Component }) {
  const { isMainUser, isValidated } = user;
  if (!isValidated || !isMainUser) {
    return <Navigate to="/authentication/sign-in/cover" replace />;
  }
  return Component;
}

ProtectedRouteMainUser.defaultProps = {
  user: {
    isMainUser: false,
    isValidated: false,
  },
};

ProtectedRouteMainUser.propTypes = {
  user: PropTypes.object,
  Component: PropTypes.node.isRequired,
};

export default ProtectedRouteMainUser;

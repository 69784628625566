import constants from "../constants";

const {
  UPDATE_ONNA_DEVICES,
  REMOVE_ONNA_DEVICES,
  GET_ONNA_DEVICES_STATE,
  UPDATE_ONNA_DEVICES_STATE,
  ADD_ONNA_DEVICE_TO_PROJECT,
  REMOVE_ONNA_DEVICES_FROM_PROJECT,
} = constants;

export const updateOnnaDevices = (data) => ({
  type: UPDATE_ONNA_DEVICES,
  data,
});

export const removeOnnaDevices = (data) => ({
  type: REMOVE_ONNA_DEVICES,
  data,
});

export const getOnnaDevicesState = (data) => ({
  type: GET_ONNA_DEVICES_STATE,
  data,
});

export const updateOnnaDevicesState = (data) => ({
  type: UPDATE_ONNA_DEVICES_STATE,
  data,
});

export const addOnnaDeviceToProject = (data) => ({
  type: ADD_ONNA_DEVICE_TO_PROJECT,
  data,
});

export const removeOnnaDevicesFromProject = (data) => ({
  type: REMOVE_ONNA_DEVICES_FROM_PROJECT,
  data,
});

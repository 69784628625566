import constants from "../constants";

const { UPDATE_PROJECTS, REMOVE_PROJECTS } = constants;

export const updateProjects = (data) => ({
  type: UPDATE_PROJECTS,
  data,
});

export const removeProjects = (data) => ({
  type: REMOVE_PROJECTS,
  data,
});

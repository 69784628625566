/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import moment from "moment";
import MDTypography from "components/MDTypography";
import AccessControlAddPasswordForm from "./accessControlAddPasswordForm";

function CellDate({ value }) {
  return <span>{value ? moment(value).format("DD/MM/YYYY HH:mm") : ""}</span>;
}

function AccessControlPasswordsForm({
  getLockPasswords,
  lockEditId,
  projectId,
  removeLockPassword,
}) {
  const [openAddPassword, setOpenAddPassword] = useState(false);
  const [lockPasswords, setLockPasswords] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();

  const columns = () => [
    { Header: t("name"), accessor: "keyboardPwdName" },
    { Header: "Password", accessor: "keyboardPwd" },
    {
      Header: t("startDate"),
      accessor: "startDate",
      Cell: CellDate,
      maxWidth: 100,
    },
    {
      Header: t("endDate"),
      accessor: "endDate",
      Cell: CellDate,
      maxWidth: 100,
    },
  ];

  useEffect(() => {
    getLockPasswords({
      lockId: lockEditId,
      projectId,
      cb: (response) => {
        if (Array.isArray(response)) {
          setLockPasswords(response);
        }
      },
    });
  }, []);

  return (
    <MDBox my={3}>
      <Modal
        open={openAddPassword}
        onClose={() => {
          setOpenAddPassword(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow: "scroll",
        }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "80vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <AccessControlAddPasswordForm
            lockId={lockEditId}
            projectId={projectId}
            setOpenAddPassword={setOpenAddPassword}
            setLockPasswords={setLockPasswords}
            lockPasswords={lockPasswords}
          />
        </MDBox>
      </Modal>
      <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
        <Card id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox
            height="4rem"
            bgColor="info"
            variant="gradient"
            coloredShadow="info"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            mt={-3}
            mx={10}
            px={2}
          >
            {t("edit")} {t("accessControl")}
          </MDBox>
          <MDBox m={3}>
            <DataTable
              table={{ columns: columns(), rows: lockPasswords }}
              canSearch
              canSelect
              canEdit
              canAdd
              add={() => {
                setOpenAddPassword(true);
              }}
              remove={(passwordsToRemove) => {
                passwordsToRemove.forEach((password) => {
                  const {
                    original: { lockId, keyboardPwdId },
                  } = password;
                  removeLockPassword({
                    lockId,
                    keyboardPwdId,
                    projectId,
                    cb: (response) => {
                      switch (response?.errcode) {
                        case 0: {
                          setErrorMessage("");
                          setLockPasswords(
                            lockPasswords.filter(
                              ({ keyboardPwdId: pwdId }) => pwdId !== keyboardPwdId
                            )
                          );
                          break;
                        }
                        case -3002: {
                          setErrorMessage(t("lockCommunicationError"));
                          break;
                        }
                        default: {
                          break;
                        }
                      }
                    },
                  });
                });
              }}
            />
          </MDBox>
          {errorMessage && (
            <MDBox m={3} display="flex" justifyContent="center">
              <MDTypography color="error" variant="body2">
                {errorMessage}
              </MDTypography>
            </MDBox>
          )}
        </Card>
      </Grid>
    </MDBox>
  );
}

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.number,
};

AccessControlPasswordsForm.defaultProps = {
  projectId: "",
  lockEditId: 0,
};

AccessControlPasswordsForm.propTypes = {
  getLockPasswords: PropTypes.func.isRequired,
  removeLockPassword: PropTypes.func.isRequired,
  lockEditId: PropTypes.number,
  projectId: PropTypes.string,
};

export default AccessControlPasswordsForm;

/**
 * Custom On/Off
 */

// prop-types is a library for typechecking of props
import React, { memo, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Chart from "components/Chart";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Modal } from "@mui/material";

function CardDevice6({ color, device, deviceState }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <Chart device={device} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        bgColor={deviceState ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && !deviceState && background.card,
        })}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          {device.visualizationType === "icon" && (
            <Icons
              name={deviceState ? device.iconOn : device.iconOff}
              size={40}
              color={darkMode ? "white" : "black"}
            />
          )}
          {device.visualizationType === "label" &&
            (deviceState ? (
              <MDTypography>{`${device.labelOn}`}</MDTypography>
            ) : (
              <MDTypography>{`${device.labelOff}`}</MDTypography>
            ))}
          {device.trend && (
            <MDBox display="flex" alignItems="center" mb={1}>
              <Icons name="Chart" size={40} color={darkMode ? "white" : "black"} />
            </MDBox>
          )}
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography
            variant="body2"
            color={deviceState ? "white" : "text"}
            textTransform="capitalize"
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of CardDevice6
CardDevice6.defaultProps = {
  color: "info",
  device: {},
  deviceState: 0,
};

// Typechecking props for the CardDevice6
CardDevice6.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  deviceState: PropTypes.number,
};

export default memo(CardDevice6, (prevProps, nextProps) => {
  const {
    device: { nodeId: nodeIdPrev },
    deviceState: deviceStatePrev,
  } = prevProps;
  const {
    device: { nodeId },
    deviceState,
  } = nextProps;

  return deviceStatePrev === deviceState && nodeIdPrev === nodeId;
});

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { useState } from "react";

// @mui material components
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useTranslation } from "react-i18next";

import DeviceCreator from "layouts/apartment/Devices/DeviceCreator";

import { endSocket } from "store/actions/socketActions";
import { sendExecuteMacroServer } from "store/actions/macroActions";
import { Icon } from "@mui/material";

function Room({ room }) {
  const [lightsDevices, setLightsDevices] = useState([]);
  const [shuttersDevices, setShuttersDevices] = useState([]);
  const [temperatureDevices, setTemperatureDevices] = useState([]);
  const [thermostatDevices, setThermostatDevices] = useState([]);
  const [suppliesDevices, setSuppliesDevices] = useState([]);
  const [alarmsDevices, setAlarmsDevices] = useState([]);
  const [othersDevices, setOthersDevices] = useState([]);
  const [accessesDevices, setAccessesDevices] = useState([]);
  const [accessControlDevices, setAccessControlDevices] = useState([]);
  const [consumptionDevices, setConsumptionDevices] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const newLightsDevices = [];
    const newShutterDevices = [];
    const newOtherDevices = [];
    const newTemperatureDevices = [];
    const newThermostatDevices = [];
    const newSuppliesDevices = [];
    const newAlarmsDevices = [];
    const newConsumptionDevices = [];
    const newAccessesDevices = [];
    const newAccessControlDevices = [];

    if (Object.hasOwnProperty.call(room, "children")) {
      room.children.forEach((device) => {
        const { deviceFunction } = device;
        switch (deviceFunction) {
          case "light":
            newLightsDevices.push(device);
            break;
          case "shutter":
            newShutterDevices.push(device);
            break;
          case "other":
            newOtherDevices.push(device);
            break;
          case "temperature":
            if (device.type === "device10") {
              newThermostatDevices.push(device);
            } else {
              newTemperatureDevices.push(device);
            }
            break;
          case "accesses":
            if (device.type === "device14") {
              newAccessControlDevices.push(device);
            } else {
              newAccessesDevices.push(device);
            }
            break;
          case "supplies":
            if (device.type === "device12") {
              newConsumptionDevices.push(device);
            } else {
              newSuppliesDevices.push(device);
            }
            break;
          case "alarm":
            newAlarmsDevices.push(device);
            break;
          default:
            break;
        }
      });
    }
    setLightsDevices(newLightsDevices);
    setShuttersDevices(newShutterDevices);
    setOthersDevices(newOtherDevices);
    setThermostatDevices(newThermostatDevices);
    setTemperatureDevices(newTemperatureDevices);
    setSuppliesDevices(newSuppliesDevices);
    setConsumptionDevices(newConsumptionDevices);
    setAlarmsDevices(newAlarmsDevices);
    setAccessesDevices(newAccessesDevices);
    setAccessControlDevices(newAccessControlDevices);
  }, [room]);

  return (
    <MDBox mt={3}>
      <MDBox mb={2} display="flex">
        <Icon>bed</Icon>
        <MDTypography style={{ marginLeft: 10 }} variant="h5">
          {room?.name.toUpperCase()}
        </MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {(accessControlDevices.length > 0 || accessesDevices.length > 0) && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("accessControls").toUpperCase()}
            </MDTypography>
            <Grid container item spacing={3}>
              {accessControlDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
              {accessesDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {(temperatureDevices.length > 0 || thermostatDevices.length > 0) && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("temperature").toUpperCase()}
            </MDTypography>
            <Grid container item spacing={3}>
              {thermostatDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
              {temperatureDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {lightsDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("lights").toUpperCase()}
            </MDTypography>
            <Grid container item spacing={3}>
              {lightsDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {consumptionDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("consumptions").toUpperCase()}
            </MDTypography>
            <Grid container spacing={3}>
              {consumptionDevices.map((device) => (
                <Grid item xs={12} md={8} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {shuttersDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("shutters").toUpperCase()}
            </MDTypography>
            <Grid container spacing={3}>
              {shuttersDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {alarmsDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("alarms").toUpperCase()}
            </MDTypography>
            <Grid container spacing={3}>
              {alarmsDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {suppliesDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("supplies").toUpperCase()}
            </MDTypography>
            <Grid container spacing={3}>
              {suppliesDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {othersDevices.length > 0 && (
          <Grid container item>
            <MDTypography style={{ marginBottom: 10 }} color="secondary" variant="h6">
              {t("others").toUpperCase()}
            </MDTypography>
            <Grid container spacing={3}>
              {othersDevices.map((device) => (
                <Grid item xs={12} md={6} lg={4} key={device.nodeId}>
                  <DeviceCreator device={device} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

Room.defaultProps = {
  room: {
    name: "",
  },
};

Room.propTypes = {
  room: PropTypes.object,
};

const mapStateToProps = (state, { onnaId, projectId, apartmentId }) => {
  const { projectsReducer, configurationReducer, onnaDevicesReducer, mainUserReducer } = state;
  let apartment = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const foundApartment of configurationReducer) {
    const { nodeId: foundNodeId } = foundApartment;
    if (foundNodeId === apartmentId) {
      apartment = foundApartment;
      break;
    }
  }

  const { uid } = mainUserReducer;

  return {
    apartment,
    onnaDevice: onnaDevicesReducer[onnaId],
    onnaId,
    project: projectsReducer && projectsReducer[projectId],
    uid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendEndSocket: () => {
    dispatch(endSocket());
  },
  actSendExecuteMacroServer: (data) => {
    dispatch(sendExecuteMacroServer(data));
  },
});

const ProjectWithConnect = connect(mapStateToProps, mapDispatchToProps)(Room);

function ProjectWrapper(props) {
  const { onnaId, projectId, apartmentId } = useParams();
  return (
    <ProjectWithConnect
      onnaId={onnaId}
      projectId={projectId}
      apartmentId={apartmentId}
      {...props}
    />
  );
}

export default ProjectWrapper;

/**
 * Access Control
 */

// prop-types is a library for typechecking of props
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useTranslation } from "react-i18next";

import AccessControlModal from "layouts/apartment/CloudApis/DeviceModals/AccessControl";
import { Grid, Icon } from "@mui/material";
import {
  accessControlGetLockDetail,
  accessControlLockUnlock,
} from "store/actions/accessControlActions";

function AccessControl(props) {
  const { projectId, lockData, actAccessControlGetLockDetail, actAccessControlLockUnlock } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [lockDetail, setLockDetail] = useState({});
  const [lockState, setLockState] = useState("locked");

  const [accessControlErrorMessage, setAccessControlErrorMessage] = useState("");

  const { lockId, lockAlias } = lockData;

  useEffect(() => {
    if (projectId && lockId) {
      actAccessControlGetLockDetail({
        projectId,
        lockId,
        cb: (response) => {
          if (response) {
            setLockDetail(response);
          } else {
            setLockDetail({});
          }
        },
      });
    }
  }, [projectId, lockId]);

  return (
    <Grid item xs={12} md={6} lg={4} key={lockId}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <AccessControlModal
            lockDetail={lockDetail}
            setLockState={(newState) => {
              if (newState === "unlock") {
                if (lockId && projectId) {
                  actAccessControlLockUnlock({
                    projectId,
                    lockId,
                    cb: (response) => {
                      switch (response?.errcode) {
                        case 0: {
                          setLockState("unlocked");
                          const { autoLockTime = 0 } = lockDetail;
                          if (lockDetail?.autoLockTime > 0) {
                            setTimeout(() => {
                              setLockState("locked");
                            }, autoLockTime * 1000);
                          }
                          setAccessControlErrorMessage("");
                          break;
                        }
                        case -3002: {
                          setAccessControlErrorMessage(t("lockCommunicationError"));
                          break;
                        }
                        default: {
                          break;
                        }
                      }
                    },
                  });
                }
              }
            }}
            lockState={lockState}
            errorMessage={accessControlErrorMessage}
          />
        </MDBox>
      </Modal>
      <Card sx={{ height: "100%", overflow: "hidden" }}>
        <MDBox
          p={2}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          onClick={() => {
            setOpen(true);
          }}
        >
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              sx={{ width: "100%", minHeight: 35 }}
            >
              <MDBox display="flex" alignItems="center" sx={{ height: 40, width: 40 }}>
                <Icon fontSize="medium">key</Icon>
              </MDBox>
              <MDBox display="flex">
                <MDTypography variant="body2" color="text" align="center">
                  {lockDetail?.electricQuantity ? `${lockDetail?.electricQuantity}%` : "?"}
                </MDTypography>
                <Icons
                  name={lockDetail?.electricQuantity > 50 ? "Battery70" : "Battery10"}
                  color={lockDetail?.electricQuantity > 50 ? "green" : "red"}
                  size={22}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox lineHeight={1}>
            <MDTypography variant="body2" color="text" textTransform="capitalize">
              {lockAlias?.toUpperCase()}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

// Setting default values for the props of AccessControl
AccessControl.defaultProps = {
  lockData: {},
  projectId: "",
};

// Typechecking props for the AccessControl
AccessControl.propTypes = {
  lockData: PropTypes.object,
  projectId: PropTypes.string,
  actAccessControlGetLockDetail: PropTypes.func.isRequired,
  actAccessControlLockUnlock: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actAccessControlGetLockDetail: (data) => {
    dispatch(accessControlGetLockDetail(data));
  },
  actAccessControlLockUnlock: (data) => {
    dispatch(accessControlLockUnlock(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);

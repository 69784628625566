import constants from "../constants";

const { KNX_STATE_FROM_SERVER, INTERNET_STATE_FROM_SERVER } = constants;

const initialState = {
  internetConnectionState: true,
  knxConnectionState: true,
};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case KNX_STATE_FROM_SERVER:
      return {
        ...state,
        knxConnectionState: action.data,
      };
    case INTERNET_STATE_FROM_SERVER:
      return {
        ...state,
        internetConnectionState: action.data,
      };
    default:
      return state;
  }
};

/**
 * Macro
 */

// prop-types is a library for typechecking of props
import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MacroEdit from "layouts/apartment/Devices/macro/MacroEdit";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function Macro({ macroData, actSendExecuteMacroServer, devices }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "80vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <MacroEdit macroData={macroData} devices={devices} setOpen={setOpen} />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        bgColor="white"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons name="MovieOpenOutline" size={40} color={darkMode ? "white" : "black"} />
          <MDBox
            mt={-0.5}
            mr={-1.5}
            onClick={(event) => {
              const { roomNodeId, bpNodeId, key } = macroData;
              event.stopPropagation();
              actSendExecuteMacroServer({
                roomNodeId: roomNodeId || bpNodeId,
                macroId: key,
              });
            }}
          >
            <Switch checked />
          </MDBox>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" color="text" textTransform="capitalize">
            {macroData?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Macro
Macro.defaultProps = {
  macroData: {},
  devices: [],
};

// Typechecking props for the Macro
Macro.propTypes = {
  macroData: PropTypes.object,
  devices: PropTypes.array,
  actSendExecuteMacroServer: PropTypes.func.isRequired,
};

export default Macro;

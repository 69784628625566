const socketConfig = () => {
  let socket = "https://onnaliving.com:4100";

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    socket = "https://onnaliving.com:5100";
  }
  return socket;
};

export default socketConfig;

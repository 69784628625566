/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";

// Settings page components
import MDTypography from "components/MDTypography";
import { storeTrendHistory, setTrendsHistory } from "store/actions/trendsActions";
import FormField from "components/FormField";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["startDate", "endDate", "type"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

function SettingsForm(props) {
  const {
    handleSubmit,
    setOpenSettings,
    errorMessage,
    historyData,
    actStoreTrendHistory,
    actSetTrendsHistory,
    trendsHistory,
  } = props;

  const onSubmit = (data) => {
    const { lastDay, lastMonth } = data;
    const { address } = historyData;
    actStoreTrendHistory({
      lastDay,
      lastMonth,
      address,
      cb: (response) => {
        if (response) {
          trendsHistory[address] = {
            ...trendsHistory[address],
            lastDay,
            lastMonth,
          };
          actSetTrendsHistory(trendsHistory);
          setOpenSettings(false);
        }
      },
    });
  };

  const { t } = useTranslation();

  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t("Settings")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="lastValue" component={FormField} label={t("lastValue")} disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastDay" component={FormField} label={t("lastDay")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="lastMonth" component={FormField} label={t("lastMonth")} />
                </Grid>
                <Grid item xs={12}>
                  <MDBox display="flex" alignItems="center" flexDirection="column" mt={2} xs={12}>
                    <MDButton type="submit" variant="gradient" color="dark" size="small">
                      {t("Save")}
                    </MDButton>
                    {errorMessage && (
                      <MDTypography color="error" variant="body2">
                        {errorMessage}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

SettingsForm.defaultProps = {
  errorMessage: "",
  historyData: {},
  trendsHistory: {},
};

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actStoreTrendHistory: PropTypes.func.isRequired,
  actSetTrendsHistory: PropTypes.func.isRequired,
  setOpenSettings: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  historyData: PropTypes.object,
  trendsHistory: PropTypes.object,
};

const mapStateToProps = (state, { historyData }) => ({
  trendsHistory: state?.trendsReducer?.trendshistory,
  initialValues: historyData,
});

const mapDispatchToProps = (dispatch) => ({
  actStoreTrendHistory: (data) => {
    dispatch(storeTrendHistory(data));
  },
  actSetTrendsHistory: (data) => {
    dispatch(setTrendsHistory(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "SettingsForm", // a unique identifier for this form
    validate,
  })(SettingsForm)
);

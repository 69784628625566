import constants from "../constants";

const {
  INIT_SOCKET,
  END_SOCKET,
  SOCKET_CONNECTION_TYPE,
  ONNA_SOCKET_STATUS,
  SERVER_SOCKET_STATUS,
  SOCKET_INFO,
} = constants;

// Socket connection LOCAL/WAN or NORMAL
export const initSocket = (data) => ({
  type: INIT_SOCKET,
  data,
});

export const endSocket = (data) => ({
  type: END_SOCKET,
  data,
});

export const actionSocketConnectionType = (payload) => ({
  type: SOCKET_CONNECTION_TYPE,
  payload,
});

export const actionOnnaSocketStatus = (payload) => ({
  type: ONNA_SOCKET_STATUS,
  payload,
});

export const actionServerSocketStatus = (payload) => ({
  type: SERVER_SOCKET_STATUS,
  payload,
});
export const actionSocketInfo = (payload) => ({
  type: SOCKET_INFO,
  payload,
});

import constants from "../constants";

const { SET_TREND_DATA, SET_TRENDS, SET_TRENDS_HISTORY } = constants;

const initialState = {
  trendData: [],
  trends: [],
  trendsHistory: {},
};
export default (state = initialState, action) => {
  if (!action) return state;
  const { type, data } = action;
  switch (type) {
    case SET_TREND_DATA:
      return {
        ...state,
        trendData: [...data],
      };
    case SET_TRENDS:
      return {
        ...state,
        ...data,
      };
    case SET_TRENDS_HISTORY:
      return {
        ...state,
        trendsHistory: { ...data },
      };
    default:
      return state;
  }
};

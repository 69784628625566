/**
 * Schedule
 */

// prop-types is a library for typechecking of props
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @mui material components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icons from "components/CustomIcons";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ScheduleEdit from "layouts/apartment/Devices/schedule/ScheduleEdit";
// import { useTranslation } from "react-i18next";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import { sendEnableSchedulesServer } from "store/actions/scheduleActions";

function Schedule({ scheduleData, devices, actSendEnableSchedulesServer, apartmentSchedules }) {
  const [open, setOpen] = useState(false);
  const [schedulesActive, setSchedulesActive] = useState(0);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const { t } = useTranslation();
  useEffect(() => {
    let active = 0;
    const { events } = scheduleData;
    events.forEach((event) => {
      if (event.scheduled === true) {
        active += 1;
      }
    });
    setSchedulesActive(active);
  }, [scheduleData]);

  const { deviceName, roomName, deviceNodeId } = scheduleData;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "80vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ScheduleEdit
            scheduleData={scheduleData}
            deviceNodeId={deviceNodeId}
            devices={devices}
            apartmentSchedules={apartmentSchedules}
            setOpen={setOpen}
          />
        </MDBox>
      </Modal>
      <MDBox
        p={2}
        height="100%"
        bgColor="white"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && background.card,
        })}
        onClick={() => {
          setOpen(true);
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <Icons name="ClockOutline" size={40} color={darkMode ? "white" : "black"} />

          <MDBox display="flex">
            {!!schedulesActive && (
              <MDTypography variant="body2" color="text" textTransform="capitalize">
                {schedulesActive}
              </MDTypography>
            )}
            <MDBox
              mt={-0.5}
              mr={-1.5}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Switch
                checked={!!schedulesActive}
                onClick={() => {
                  actSendEnableSchedulesServer({ nodeId: deviceNodeId, enable: !schedulesActive });
                }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography variant="body2" color="text" textTransform="capitalize">
            {deviceName?.toUpperCase()}
          </MDTypography>
          <MDTypography variant="caption" color="text" textTransform="capitalize">
            {roomName?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Schedule
Schedule.defaultProps = {
  scheduleData: {},
  apartmentSchedules: {},
  devices: [],
};

// Typechecking props for the Schedule
Schedule.propTypes = {
  scheduleData: PropTypes.object,
  apartmentSchedules: PropTypes.object,
  devices: PropTypes.array,
  actSendEnableSchedulesServer: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actSendEnableSchedulesServer: (deviceId, enable) => {
    dispatch(sendEnableSchedulesServer(deviceId, enable));
  },
});

export default connect(null, mapDispatchToProps)(Schedule);

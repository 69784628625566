import constants from "../constants";

const { EDIT_ADDRESS_STORE, SEND_VALUE_SERVER, INIT_ADDRESSES_STORE } = constants;

export const initAddressesStore = (addresses) => ({
  type: INIT_ADDRESSES_STORE,
  addresses,
});

export const sendValueServer = (id, updates) => ({
  type: SEND_VALUE_SERVER,
  updates,
});

export const editAddressStore = (updates) => ({
  type: EDIT_ADDRESS_STORE,
  id: updates.id,
  updates,
});

import constants from "../constants";

const { KNX_STATE_FROM_SERVER, INTERNET_STATE_FROM_SERVER } = constants;

export const knxState = (data) => ({
  type: KNX_STATE_FROM_SERVER,
  data,
});

export const internetState = (data) => ({
  type: INTERNET_STATE_FROM_SERVER,
  data,
});

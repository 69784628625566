/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableNotifications from "components/DataTableNotifications";
import {
  sendReadNotificationServer,
  sendRemoveNotificationServer,
} from "store/actions/notificationsActions";

function CellDate({ value }) {
  return <span>{moment(value).format("DD/MM/YYYY HH:mm")}</span>;
}

const columns = () => {
  const { t } = useTranslation();
  return [
    { Header: t("message"), accessor: "title", width: 20 },
    {
      Header: t("createdAt"),
      accessor: "createdAt",
      Cell: CellDate,
      maxWidth: 100,
    },
  ];
};

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Notifications({
  notifications,
  projects,
  apartments,
  userData,
  actSendReadNotificationServer,
  actSendRemoveNotificationServer,
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [uids, setUids] = useState([]);
  const [actionType, setActionType] = useState("");
  const [formatedData, setFormatedData] = useState([]);
  const { isClient } = userData;
  const { t } = useTranslation();

  const remove = (indexes) => {
    const notificationsIndexes = [];
    indexes.forEach((notification) => {
      const {
        original: { id, apartmentId },
      } = notification;
      notificationsIndexes.push({ id, apartmentId });
    });
    setActionType("remove");
    setUids(notificationsIndexes);
    setOpenConfirm(true);
  };

  const confirm = () => {
    switch (actionType) {
      case "remove": {
        const { id, apartmentId } = uids[0];
        actSendRemoveNotificationServer(id, apartmentId);
        setActionType("");
        setOpenConfirm(false);
        break;
      }
      default:
        break;
    }
  };

  const read = (data) => {
    data.forEach((notification) => {
      const { original } = notification;
      const { id, apartmentId } = original;
      actSendReadNotificationServer(id, apartmentId);
    });
    setActionType("read");
  };

  useEffect(() => {
    const notificationsArray = Object.values(notifications || {});
    const formatedNotifications = notificationsArray.map((notification) => {
      const { projectId, apartmentId } = notification;
      return {
        ...notification,
        projectName: projects[projectId]?.name || projectId,
        apartmentName: apartments[apartmentId]?.name || apartmentId,
      };
    });
    setFormatedData(formatedNotifications);
  }, [notifications]);

  return (
    <DashboardLayout>
      <AlertDialog
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAgree={() => {
          confirm();
        }}
      />
      <DashboardNavbar
        title="notifications"
        route={isClient ? "/client/notifications" : "/notifications"}
        isClient={isClient}
      />
      <MDBox pt={6} pb={3}>
        <Card>
          <CardTitle title={t("notifications")} icon="notifications" />
          <DataTableNotifications
            table={{
              columns: columns(),
              rows: formatedData,
            }}
            canSearch
            canSelect
            read={read}
            remove={remove}
            actionIcon="check"
            actionColor="success"
            multipleSelect={false}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Notifications.defaultProps = {
  notifications: {},
  projects: {},
  apartments: {},
  userData: {},
};

Notifications.propTypes = {
  notifications: PropTypes.object,
  projects: PropTypes.object,
  apartments: PropTypes.object,
  userData: PropTypes.object,
  actSendReadNotificationServer: PropTypes.func.isRequired,
  actSendRemoveNotificationServer: PropTypes.func.isRequired,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

CellDate.defaultProps = {
  value: 0,
};

CellDate.propTypes = {
  value: PropTypes.any,
};

const mapStateToProps = (state) => ({
  notifications: state?.notificationsReducer?.notifications,
  projects: state?.projectsReducer,
  onnaDevices: state?.onnaDevicesReducer,
  apartments: state?.apartmentsReducer,
  userData: state?.mainUserReducer,
});

const mapDispatchToProps = (dispatch) => ({
  actSendReadNotificationServer: (id, apartmentId) => {
    dispatch(sendReadNotificationServer(id, apartmentId));
  },
  actSendRemoveNotificationServer: (id, apartmentId) => {
    dispatch(sendRemoveNotificationServer(id, apartmentId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

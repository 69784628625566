/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";

// Settings page components
import FormSelector from "components/FormSelector";
import DataTable from "components/FormDataTable";

import {
  sendEditScheduleServer,
  sendNewScheduleServer,
  sendRemoveScheduleServer,
} from "store/actions/scheduleActions";
import Modal from "@mui/material/Modal";
import EventAddEditForm from "layouts/apartment/Devices/EventAddEditForm";

function CellState({ value }) {
  if (value) {
    return <Icon color="success">circle</Icon>;
  }
  return <Icon color="error">circle</Icon>;
}

const columns = () => {
  const { t } = useTranslation();

  return [
    { Header: t("time"), accessor: "time", width: 20 },
    { Header: t("days"), accessor: "days", width: 20 },
    { Header: t("value"), accessor: "valueText", width: 20 },
    { Header: t("scheduled"), accessor: "scheduled", Cell: CellState, width: 20 },
  ];
};

const formatData = (data, t) => {
  const editedEvents = [];
  const weekDays = t("weekDays", { returnObjects: true });
  data.forEach((event) => {
    const { hour, minute, weekDay } = event;
    let weekDaysString = "[";
    weekDay.forEach((day, index) => {
      weekDaysString += weekDays[day - 1].toUpperCase();
      if (index !== weekDay.length - 1) {
        weekDaysString += ", ";
      }
    });
    weekDaysString += "]";

    editedEvents.push({
      ...event,
      time: `${hour}:${minute >= 10 ? minute : `0${minute}`}`,
      days: weekDaysString,
    });
  });
  return editedEvents;
};

function ScheduleForm(props) {
  const {
    handleSubmit,
    setOpen,
    actSendEditScheduleServer,
    actSendNewScheduleServer,
    actSendRemoveScheduleServer,
    apartmentSchedules,
    newEvents,
    deviceId,
    devices,
    change,
  } = props;

  const [nameType, setNameType] = useState("schedule");
  const [actionType, setActionType] = useState("add");
  const [selectedEvent, setSelectedEvent] = useState({});
  const { t } = useTranslation();

  const getDevices = () => {
    const uids = [];
    devices.forEach((device) => {
      const { nodeId: newNodeId } = device;
      uids.push(newNodeId);
    });
    return uids;
  };

  const getDevicesNames = () => {
    const uids = [];
    devices.forEach((device) => {
      const { nodeId: newNodeId, name: newName } = device;
      uids.push({ uid: newNodeId, name: newName });
    });
    return uids;
  };

  useEffect(() => {
    const newData = formatData(newEvents, t);
    change("events", newData);
  }, [newEvents]);

  useEffect(() => {
    if (Object.hasOwnProperty.call(apartmentSchedules, deviceId)) {
      if (Object.hasOwnProperty.call(apartmentSchedules[deviceId], "events")) {
        const newDeviceEvents = apartmentSchedules[deviceId]?.events;
        const newData = formatData(newDeviceEvents, t);
        change("events", newData);
      } else {
        change("events", []);
      }
    } else {
      change("events", []);
    }
  }, [deviceId]);

  useEffect(() => {
    if (Object.hasOwnProperty.call(apartmentSchedules, deviceId)) {
      if (Object.hasOwnProperty.call(apartmentSchedules[deviceId], "events")) {
        const newDeviceEvents = apartmentSchedules[deviceId]?.events;
        const newData = formatData(newDeviceEvents, t);
        change("events", newData);
      } else {
        change("events", []);
      }
    } else {
      change("events", []);
    }
  }, [JSON.stringify(apartmentSchedules)]);

  const submitData = () => {
    setOpen(false);
  };

  const add = () => {
    setNameType("device");
    setActionType("add");
  };

  const edit = (data) => {
    setSelectedEvent(data[0].original);
    setActionType("edit");
    setNameType("device");
  };

  const remove = (data) => {
    const { nodeId, id } = data[0].original;
    actSendRemoveScheduleServer({ nodeId, id });
  };

  return (
    <MDBox my={3}>
      <form onSubmit={handleSubmit(submitData)}>
        <Modal
          open={nameType === "device"}
          onClose={() => {
            setNameType("schedule");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: "scroll",
          }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "80vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <EventAddEditForm
              devices={devices}
              selectedEvent={selectedEvent}
              deviceNodeId={deviceId}
              key={selectedEvent?.key}
              onSubmit={(newEvent) => {
                if (actionType === "edit") {
                  actSendEditScheduleServer(newEvent);
                } else {
                  actSendNewScheduleServer(newEvent);
                }
                change("selectedEvent", "");
                setNameType("schedule");
              }}
              actionType={actionType}
            />
          </MDBox>
        </Modal>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t("edit")} {t("schedule")}
            </MDBox>
            <MDBox m={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="deviceId"
                    values={getDevices()}
                    names={getDevicesNames()}
                    component={FormSelector}
                    label={t("device")}
                    disabled={actionType === "edit"}
                  />
                </Grid>
                <Field
                  name="events"
                  component={DataTable}
                  label={t("events")}
                  {...{
                    table: {
                      columns: columns(),
                    },
                    canAdd: true,
                    canEdit: true,
                    canSelect: true,
                    actionIcon: "arrow_forward_ios",
                    actionColor: "info",
                    add: () => {
                      add();
                    },
                    edit: (data) => {
                      edit(data);
                    },
                    remove: (data) => {
                      remove(data);
                    },
                  }}
                />
                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
                    <MDButton type="submit" variant="gradient" color="dark" size="small">
                      {t("Save")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

CellState.defaultProps = {
  value: false,
};

CellState.propTypes = {
  value: PropTypes.bool,
};

ScheduleForm.defaultProps = {
  newEvents: [],
  devices: [],
  deviceId: "",
  apartmentSchedules: {},
};

ScheduleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  apartmentSchedules: PropTypes.object,
  devices: PropTypes.array,
  actSendEditScheduleServer: PropTypes.func.isRequired,
  actSendNewScheduleServer: PropTypes.func.isRequired,
  actSendRemoveScheduleServer: PropTypes.func.isRequired,
  newEvents: PropTypes.array,
  deviceId: PropTypes.string,
  change: PropTypes.func.isRequired,
};

const selector = formValueSelector("ScheduleForm");

const mapStateToProps = (state, props) => {
  const { deviceNodeId, apartmentSchedules } = props;

  return {
    deviceId: selector(state, "deviceId"),
    apartmentSchedules,
    initialValues: {
      deviceId: deviceNodeId,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  actSendEditScheduleServer: (data) => {
    dispatch(sendEditScheduleServer(data));
  },
  actSendNewScheduleServer: (data) => {
    dispatch(sendNewScheduleServer(data));
  },
  actSendRemoveScheduleServer: (data) => {
    dispatch(sendRemoveScheduleServer(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "ScheduleForm", // a unique identifier for this form
  })(ScheduleForm)
);

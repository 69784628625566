/**
 * Thermostat
 */

// prop-types is a library for typechecking of props
import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Icons from "components/CustomIcons";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSlider from "components/MDSlider";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function ModalDevice10(props) {
  const {
    device,
    realTempInput,
    onOffStateInput,
    setpointStateInput,
    fanStateInput,
    fanAutoManualStateInput,
    modeStateInput,
    customButtonInput,
    customControlStateInput,
    heatingCoolingChangeStateInput,
    basicCoolingState1BitInput,
    basicCoolingState1ByteInput,
    basicHeatingState1BitInput,
    basicHeatingState1ByteInput,
    additionalCoolingState1BitInput,
    additionalCoolingState1ByteInput,
    additionalHeatingState1BitInput,
    additionalHeatingState1ByteInput,
    color,
    sendValueServer,
  } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [modeValuesToShow, setModeValuesToShow] = useState([]);

  const [tabIndex, setTabIndex] = React.useState("1");

  const { t } = useTranslation();

  const handleTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleTabIndex2 = (event, newValue) => {
    setTabIndex((parseInt(newValue, 10) + 3).toString());
  };

  const {
    basicType,
    heatingCoolingChange,
    heatingCoolingChangeState,
    heatingCoolingChangeAddress,
    additionalStage,
    demandState,
    onOffInverted,
    fan,
    fanValues,
    fanAddress,
    fanType,
    fanAutoManual,
    fanAutoManualAddress,
    fanAutoManualInverted,
    mode,
    modeFixedValues,
    modeAddress,
    customButton,
    customButtonName,
    customButtonLabelOn,
    customButtonLabelOff,
    customButtonAddress,
    customControl,
    customControlName,
    customControlAddress,
    customControlValues,
  } = device;

  const modeValues = [
    { label: t("auto").toUpperCase(), value: 0 },
    { label: t("comfort").toUpperCase(), value: 1 },
    { label: t("standby").toUpperCase(), value: 2 },
    { label: t("night").toUpperCase(), value: 3 },
    { label: t("protection").toUpperCase(), value: 4 },
  ];

  useEffect(() => {
    if (mode) {
      const modeValuesToSet = modeValues.filter((value, index) => modeFixedValues[index]);
      setModeValuesToShow(modeValuesToSet);
    }
  }, [modeFixedValues]);

  const cooling = basicCoolingState1BitInput || basicCoolingState1ByteInput > 0;
  const heating = basicHeatingState1BitInput || basicHeatingState1ByteInput > 0;
  const additionalCooling = additionalCoolingState1BitInput || additionalCoolingState1ByteInput > 0;
  const additionalHeating = additionalHeatingState1BitInput || additionalHeatingState1ByteInput > 0;
  const basicTypeForced = basicType === "heating" ? 1 : 0;
  const heatingCoolingForcedValue = heatingCoolingChangeState
    ? heatingCoolingChangeStateInput
    : basicTypeForced;

  function setpointControl() {
    return (
      <MDBox minHeight={125}>
        <MDBox mt={1} mx={2}>
          <MDSlider
            onChange={(value) => {
              sendValueServer(device.setpointAddress, {
                id: device.setpointAddress,
                value,
              });
            }}
            value={setpointStateInput}
            color="error"
            aria-label="Default"
            valueLabelDisplay="auto"
            min={7}
            max={40}
            step={0.5}
          />
        </MDBox>
        <MDBox mb={1} lineHeight={1} display="flex" justifyContent="center">
          <MDTypography
            variant="h2"
            color={onOffStateInput ? "white" : "text"}
          >{`${setpointStateInput.toFixed(1)}ºC`}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function selectorControl(fixedValues, currentValue, address) {
    return (
      <Grid container display="flex" justifyContent="center">
        {fixedValues.map((item) => {
          const { value, label } = item;
          return (
            <MDBox m={1} key={value + label}>
              <MDButton
                color={currentValue === parseFloat(value) ? "info" : "dark"}
                onClick={() => {
                  sendValueServer(address, {
                    id: address,
                    value: parseFloat(value),
                  });
                }}
              >
                <MDTypography color="white" textTransform="capitalize">
                  {label?.toUpperCase()}
                </MDTypography>
              </MDButton>
            </MDBox>
          );
        })}
      </Grid>
    );
  }

  function selectorLabel(fixedValues, currentValue) {
    const foundValue = fixedValues.filter(({ value }) => parseFloat(value) === currentValue);
    return foundValue.length > 0 ? foundValue[0].label?.toUpperCase() : t("noMatch");
  }

  const fanStateGenerator = () => {
    let fanAutoManualString = "";
    if (fanAutoManualInverted === "direct")
      fanAutoManualString = fanAutoManualStateInput
        ? t("manual").toUpperCase()
        : t("auto").toUpperCase();
    else
      fanAutoManualString = fanAutoManualStateInput
        ? t("auto").toUpperCase()
        : t("manual").toUpperCase();

    let fanString = fanAutoManual && fan ? fanAutoManualString : "";

    fanString = `${fanString} ${Math.round(fanStateInput)}${fanType === "percent" && "%"}`;
    fanValues.forEach((fanValue) => {
      const { value, label } = fanValue;
      if (Math.round(fanStateInput).toString() === value) {
        fanString = fanAutoManual ? fanAutoManualString : "";
        fanString = `${fanString} ${label}`;
      }
    });

    return fanString;
  };

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgColor={onOffStateInput ? color : "white"}
        sx={({ palette: { background } }) => ({
          background: darkMode && !onOffStateInput && background.card,
        })}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <MDBox display="flex" sx={{ minWidth: 150 }}>
            <Icons name="Thermometer" size={40} color={darkMode ? "white" : "black"} />
            <MDTypography color={onOffStateInput ? "white" : "text"}>{`${realTempInput.toFixed(
              1
            )}ºC`}</MDTypography>
          </MDBox>
          {demandState && heatingCoolingForcedValue === 1 && (
            <MDBox>
              {demandState && (
                <MDBox>
                  <Icons name="Radiator" size={40} color={heating ? "#FA9D27" : "#AAA"} />
                  {additionalStage && additionalHeating && (
                    <Icons name="Fan" size={40} color={additionalHeating ? "#FA9D27" : "#AAA"} />
                  )}
                </MDBox>
              )}
            </MDBox>
          )}
          {demandState && heatingCoolingForcedValue === 0 && (
            <MDBox>
              {demandState && (
                <MDBox>
                  <Icons name="Snowflake" size={40} color={cooling ? "#FA9D27" : "#AAA"} />
                  {additionalStage && additionalCooling && (
                    <Icons name="Fan" size={40} color={additionalCooling ? "#FA9D27" : "#AAA"} />
                  )}
                </MDBox>
              )}
            </MDBox>
          )}
          <MDBox
            mt={-0.5}
            mr={-1}
            sx={{ minWidth: 150 }}
            display="flex"
            justifyContent="flex-end"
            onClick={(event) => {
              event.stopPropagation();
              sendValueServer(device.onOffAddress, {
                id: device.onOffAddress,
                value: onOffStateInput ? 0 : 1,
              });
            }}
          >
            <Switch checked={onOffInverted ? !onOffStateInput : !!onOffStateInput} />
          </MDBox>
        </MDBox>
        <MDBox sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabIndex}>
            <TabPanel value="1">{setpointControl()}</TabPanel>
            {fan && (
              <TabPanel value="2">
                <MDBox minHeight={125} justifyContent="center">
                  <MDBox>{selectorControl(fanValues, fanStateInput, fanAddress)}</MDBox>
                  <MDBox>
                    {selectorControl(
                      [
                        {
                          value: fanAutoManualInverted === "direct" ? 1 : 0,
                          label: t("manual").toUpperCase(),
                        },
                        {
                          value: fanAutoManualInverted === "direct" ? 0 : 1,
                          label: t("auto").toUpperCase(),
                        },
                      ],
                      fanAutoManualStateInput,
                      fanAutoManualAddress
                    )}
                  </MDBox>
                </MDBox>
              </TabPanel>
            )}
            {mode && (
              <TabPanel value="3">
                <MDBox minHeight={125} display="flex" alignItems="center">
                  {selectorControl(modeValuesToShow, modeStateInput, modeAddress)}
                </MDBox>
              </TabPanel>
            )}
            {customButton && (
              <TabPanel value="4">
                <MDBox minHeight={125} display="flex" alignItems="center">
                  {selectorControl(
                    [
                      { label: customButtonLabelOn, value: 1 },
                      { label: customButtonLabelOff, value: 0 },
                    ],
                    customButtonInput,
                    customButtonAddress
                  )}
                </MDBox>
              </TabPanel>
            )}
            {heatingCoolingChange && (
              <TabPanel value="5">
                <MDBox minHeight={125} display="flex" alignItems="center">
                  {selectorControl(
                    [
                      { label: t("heat"), value: 1 },
                      { label: t("cool"), value: 0 },
                    ],
                    heatingCoolingChangeStateInput,
                    heatingCoolingChangeAddress
                  )}
                </MDBox>
              </TabPanel>
            )}
            {customControl && (
              <TabPanel value="6">
                <MDBox minHeight={125} display="flex" alignItems="center">
                  {selectorControl(
                    customControlValues,
                    customControlStateInput,
                    customControlAddress
                  )}
                </MDBox>
              </TabPanel>
            )}
            <MDBox mb={1}>
              <Tabs
                value={parseInt(tabIndex, 10) <= 3 ? tabIndex : false}
                onChange={handleTabIndex}
              >
                <Tab
                  label={
                    <MDBox>
                      <MDTypography variant="body1">{`${setpointStateInput.toFixed(
                        1
                      )}ºC`}</MDTypography>
                      <MDTypography variant="body2" textTransform="capitalize">
                        {t("setpoint").toUpperCase()}
                      </MDTypography>
                    </MDBox>
                  }
                  value="1"
                />
                {fan && (
                  <Tab
                    label={
                      <MDBox>
                        <MDTypography variant="body1">{fanStateGenerator()}</MDTypography>
                        <MDTypography variant="body2" textTransform="capitalize">
                          {t("fan").toUpperCase()}
                        </MDTypography>
                      </MDBox>
                    }
                    value="2"
                  />
                )}
                {mode && (
                  <Tab
                    label={
                      <MDBox>
                        <MDTypography variant="body1">
                          {selectorLabel(modeValuesToShow, modeStateInput)}
                        </MDTypography>
                        <MDTypography variant="body2" textTransform="capitalize">
                          {t("mode").toUpperCase()}
                        </MDTypography>
                      </MDBox>
                    }
                    value="3"
                  />
                )}
              </Tabs>
            </MDBox>
            {(customButton || heatingCoolingChangeState || customControl) && (
              <MDBox>
                <Tabs
                  value={
                    parseInt(tabIndex, 10) > 3 ? (parseInt(tabIndex, 10) - 3).toString() : false
                  }
                  onChange={handleTabIndex2}
                >
                  {customButton && (
                    <Tab
                      label={
                        <MDBox>
                          <MDTypography variant="body1">
                            {selectorLabel(
                              [
                                { label: customButtonLabelOn?.toUpperCase(), value: 1 },
                                { label: customButtonLabelOff?.toUpperCase(), value: 0 },
                              ],
                              customButtonInput
                            )}
                          </MDTypography>
                          <MDTypography variant="body2" textTransform="capitalize">
                            {customButtonName?.toUpperCase()}
                          </MDTypography>
                        </MDBox>
                      }
                      value="1"
                    />
                  )}
                  {heatingCoolingChangeState && (
                    <Tab
                      label={
                        <MDBox>
                          <MDTypography variant="body1">
                            {selectorLabel(
                              [
                                { label: t("heat").toUpperCase(), value: 1 },
                                { label: t("cool").toUpperCase(), value: 0 },
                              ],
                              heatingCoolingChangeStateInput
                            )}
                          </MDTypography>
                          <MDTypography variant="body2" textTransform="capitalize">
                            {t("state").toUpperCase()}
                          </MDTypography>
                        </MDBox>
                      }
                      value="2"
                    />
                  )}
                  {customControl && (
                    <Tab
                      label={
                        <MDBox>
                          <MDTypography variant="body1">
                            {selectorLabel(
                              customControlValues,
                              customControlStateInput
                            )?.toUpperCase()}
                          </MDTypography>
                          <MDTypography variant="body2" textTransform="capitalize">
                            {customControlName?.toUpperCase()}
                          </MDTypography>
                        </MDBox>
                      }
                      value="3"
                    />
                  )}
                </Tabs>
              </MDBox>
            )}
          </TabContext>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <MDTypography
            variant="body2"
            textTransform="capitalize"
            color={onOffStateInput ? "white" : "text"}
          >
            {device?.name?.toUpperCase()}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ModalDevice10
ModalDevice10.defaultProps = {
  color: "info",
  device: {},
  onOffStateInput: 0,
  fanStateInput: 0,
  fanAutoManualStateInput: 0,
  modeStateInput: 0,
  customButtonInput: 0,
  customControlStateInput: 0,
  realTempInput: 0,
  setpointStateInput: 0,
  heatingCoolingChangeStateInput: 0,
  basicCoolingState1BitInput: 0,
  basicCoolingState1ByteInput: 0,
  basicHeatingState1BitInput: 0,
  basicHeatingState1ByteInput: 0,
  additionalCoolingState1BitInput: 0,
  additionalCoolingState1ByteInput: 0,
  additionalHeatingState1BitInput: 0,
  additionalHeatingState1ByteInput: 0,
};

// Typechecking props for the ModalDevice10
ModalDevice10.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  device: PropTypes.object,
  onOffStateInput: PropTypes.number,
  fanStateInput: PropTypes.number,
  fanAutoManualStateInput: PropTypes.number,
  modeStateInput: PropTypes.number,
  customControlStateInput: PropTypes.number,
  realTempInput: PropTypes.number,
  setpointStateInput: PropTypes.number,
  customButtonInput: PropTypes.number,
  heatingCoolingChangeStateInput: PropTypes.number,
  basicCoolingState1BitInput: PropTypes.number,
  basicCoolingState1ByteInput: PropTypes.number,
  basicHeatingState1BitInput: PropTypes.number,
  basicHeatingState1ByteInput: PropTypes.number,
  additionalCoolingState1BitInput: PropTypes.number,
  additionalCoolingState1ByteInput: PropTypes.number,
  additionalHeatingState1BitInput: PropTypes.number,
  additionalHeatingState1ByteInput: PropTypes.number,
  sendValueServer: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { knxAddressReducer = [] } = state;
  const { device } = props;
  const addressObject = {};
  const keys = Object.keys(device);
  keys.forEach((key) => {
    if (key.includes("Address") && device[key]) {
      const address = knxAddressReducer.find((foundAddress) => foundAddress.id === device[key]);
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (address) addressObject[newKey] = address.value;
    }
  });

  return addressObject;
};

export default connect(mapStateToProps)(
  memo(ModalDevice10, (prevProps, nextProps) => {
    const { device } = prevProps;
    const { nodeId: nodeIdPrev } = device;
    const {
      device: { nodeId },
    } = nextProps;
    const test = [];
    const keys = Object.keys(device);
    keys.forEach((key) => {
      const newKey = `${key.slice(0, key.lastIndexOf("Address"))}Input`;
      if (key.includes("Address") && device[key]) {
        test.push(prevProps[newKey] !== nextProps[key]);
      }
    });
    return test.indexOf(true) === -1 && nodeIdPrev === nodeId;
  })
);

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

function FormTimePicker({ input, ...rest }) {
  const { label } = rest;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...rest}
        renderInput={(params) => <MDInput {...params} variant="standard" fullWidth />}
        label={label}
        value={input.value}
        onChange={(newValue) => {
          input.onChange(newValue);
        }}
      />
    </LocalizationProvider>
  );
}

// Setting default values for the props of FormDatePîcker
FormTimePicker.defaultProps = {
  label: " ",
  input: {},
  meta: {
    touched: false,
    invalid: false,
    error: "",
  },
};

// Typechecking props for FormTimePicker
FormTimePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default FormTimePicker;

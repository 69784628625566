import constants from "../constants";

const {
  UPDATE_NOTIFICATIONS,
  REMOVE_NOTIFICATIONS,
  UPDATE_BADGE_NOTIFICATIONS,
  SEND_READ_NOTIFICATION_SERVER,
  SEND_REMOVE_NOTIFICATION_SERVER,
} = constants;

export const updateNotifications = (data) => ({
  type: UPDATE_NOTIFICATIONS,
  data,
});

export const removeNotifications = () => ({
  type: REMOVE_NOTIFICATIONS,
});

export const updateBadgeNotifications = (data) => ({
  type: UPDATE_BADGE_NOTIFICATIONS,
  data,
});

export const sendReadNotificationServer = (id, apartmentId) => ({
  type: SEND_READ_NOTIFICATION_SERVER,
  id,
  apartmentId,
});

export const sendRemoveNotificationServer = (id, apartmentId) => ({
  type: SEND_REMOVE_NOTIFICATION_SERVER,
  id,
  apartmentId,
});

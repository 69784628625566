import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

function ProtectedRouteClient({ user, Component }) {
  const { isClient, isValidated } = user;
  if (!isValidated || !isClient) {
    return <Navigate to="/client/sign-in" replace />;
  }
  return Component;
}

ProtectedRouteClient.defaultProps = {
  user: {
    isClient: false,
    isValidated: false,
  },
};

ProtectedRouteClient.propTypes = {
  user: PropTypes.object,
  Component: PropTypes.node.isRequired,
};

export default ProtectedRouteClient;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import OnnaDevices from "layouts/project/components/onnaDevices";
import Apartments from "layouts/project/components/apartments";
import Reservations from "layouts/project/components/reservations";
import Footer from "examples/Footer";

import { getOnnaDevicesState } from "store/actions/onnaDevicesActions";
import MDAppBar from "components/MDAppBar";
import Occupancy from "layouts/project//components/occupancy";
import CloudApis from "layouts/project//components/cloudApis";

function AlertDialog({ open, handleClose, handleAgree }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MDBox
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.sidenav : white.main,
        })}
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("stepNotUndo")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t("cancel")}</MDButton>
          <MDButton onClick={handleAgree} autoFocus>
            {t("OK")}
          </MDButton>
        </DialogActions>
      </MDBox>
    </Dialog>
  );
}

function Project({
  database,
  onnaDevices,
  reservations,
  apartments,
  project,
  sendGetOnnaDevicesState,
  projectId,
  clients,
}) {
  useEffect(() => {
    sendGetOnnaDevicesState(Object.keys(onnaDevices));
  }, [JSON.stringify(onnaDevices)]);

  const [tabValue, setTabValue] = useState(0);

  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <AlertDialog open={false} handleClose={() => {}} handleAgree={() => {}} />
      <Modal
        open={false}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "65vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <div>FORM</div>
        </MDBox>
      </Modal>
      <DashboardNavbar
        title={project?.name}
        route={`/projects/${project?.name}`}
        firstLink="/projects/"
      />
      <MDAppBar
        tabsInfo={[
          { label: t("apartments"), icon: "house" },
          { label: t("occupancy"), icon: "group" },
          { label: t("reservations"), icon: "access_time" },
          { label: t("onnaDevices"), icon: "hub" },
          { label: "Cloud APIs", icon: "cloud" },
        ]}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {tabValue === 0 && (
        <MDBox pt={6} pb={3}>
          <Apartments
            database={database}
            projectId={projectId}
            apartments={apartments}
            onnaDevices={onnaDevices}
            clients={clients}
          />
        </MDBox>
      )}
      {tabValue === 1 && (
        <MDBox pt={6} pb={3}>
          <Occupancy database={database} apartments={apartments} project={project} />
        </MDBox>
      )}
      {tabValue === 2 && (
        <MDBox pt={6} pb={3}>
          <Reservations
            database={database}
            projectId={projectId}
            apartments={apartments}
            reservations={reservations}
            clients={clients}
          />
        </MDBox>
      )}
      {tabValue === 3 && (
        <MDBox pt={6} pb={3}>
          <OnnaDevices database={database} projectId={projectId} onnaDevices={onnaDevices} />
        </MDBox>
      )}
      {tabValue === 4 && (
        <MDBox pt={6} pb={3}>
          <CloudApis database={database} projectId={projectId} project={project} />
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

Project.defaultProps = {
  onnaDevices: {},
  reservations: {},
  apartments: {},
  project: {},
  projectId: "",
  clients: {},
};

Project.propTypes = {
  database: PropTypes.object.isRequired,
  onnaDevices: PropTypes.object,
  reservations: PropTypes.object,
  apartments: PropTypes.object,
  sendGetOnnaDevicesState: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  project: PropTypes.object,
  clients: PropTypes.object,
};

AlertDialog.defaultProps = {
  handleAgree: () => {},
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
};

const mapStateToProps = (state, { projectId }) => {
  const {
    projectsReducer,
    onnaDevicesReducer,
    apartmentsReducer,
    clientsReducer,
    reservationsReducer,
  } = state;
  const project = projectsReducer ? projectsReducer[projectId] : {};
  const onnaDevicesIds = project?.onnaDevices;
  const reservationsIds = project?.reservations;
  const onnaDevices = {};
  const newReservations = {};
  const apartmentsIds = project?.apartments;
  const apartments = {};
  if (onnaDevicesIds) {
    Object.keys(onnaDevicesIds).forEach((id) => {
      if (Object.prototype.hasOwnProperty.call(onnaDevicesReducer, id)) {
        onnaDevices[id] = onnaDevicesReducer[id];
      }
    });
  }
  if (apartmentsIds) {
    Object.keys(apartmentsIds).forEach((id) => {
      if (Object.prototype.hasOwnProperty.call(apartmentsReducer, id)) {
        apartments[id] = apartmentsReducer[id];
      }
    });
  }
  if (reservationsIds) {
    Object.keys(reservationsIds).forEach((id) => {
      const { reservations } = reservationsReducer;
      if (Object.prototype.hasOwnProperty.call(reservations, id)) {
        newReservations[id] = reservations[id];
      }
    });
  }

  return {
    project,
    onnaDevices,
    apartments,
    reservations: newReservations,
    clients: clientsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendGetOnnaDevicesState: (onnaIds) => {
    dispatch(getOnnaDevicesState(onnaIds));
  },
});

const ProjectWithConnect = connect(mapStateToProps, mapDispatchToProps)(Project);

function ProjectWrapper(props) {
  const { projectId } = useParams();
  return <ProjectWithConnect projectId={projectId} {...props} />;
}

export default ProjectWrapper;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
// import moment from "moment";

// Material Dashboard 2 PRO React components
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import CardTitle from "components/CardTitle";
import { useTranslation } from "react-i18next";
// import MDInput from "components/MDInput";
import { Grid, TextField } from "@mui/material";

function OnnaDeviceInfo({ settings, systemState, sendUpdateAppAction }) {
  const { t } = useTranslation();
  const {
    ipAddress = "127.0.0.1",
    addressType = "dhcp",
    hardwareVersion = "1.0.0",
    appVersion = "1.0.0",
    onnaModel = "unknown",
    onnaId = "unknown",
  } = settings;

  return (
    <Card>
      <CardTitle title={t("onnaDevice")} icon="hub" />
      <MDBox pt={3} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t("IP Address")}
              value={ipAddress}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t("IP Address Type")}
              value={addressType?.toUpperCase()}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t("Hardware Version")}
              value={hardwareVersion}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox display="flex">
              <TextField
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("App Version")}
                value={appVersion}
                InputProps={{
                  readOnly: true,
                }}
              />
              <MDBox ml={2}>
                <MDButton
                  onClick={() => {
                    sendUpdateAppAction(true);
                  }}
                  color="secondary"
                  variant="contained"
                  endIcon={<Icon>autorenew</Icon>}
                >
                  {t("Update")}
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t("Onna Model")}
              value={onnaModel}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Onna ID"
              value={onnaId}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="KNX"
              value={systemState?.knxConnectionState ? "TRUE" : "FALSE"}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

OnnaDeviceInfo.defaultProps = {
  settings: {},
  systemState: {
    internetConnectionState: false,
    knxConnectionState: false,
  },
};

OnnaDeviceInfo.propTypes = {
  settings: PropTypes.object,
  systemState: PropTypes.object,
  sendUpdateAppAction: PropTypes.func.isRequired,
};

export default OnnaDeviceInfo;

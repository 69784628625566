import constants from "../constants";

const { UPDATE_OCCUPANCY, REMOVE_OCCUPANCY } = constants;

const initialState = {};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_OCCUPANCY: {
      return { ...state, ...action.data };
    }
    case REMOVE_OCCUPANCY: {
      return {};
    }
    default:
      return state;
  }
};

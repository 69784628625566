import constants from "../constants";

const {
  UPDATE_RESERVATIONS,
  REMOVE_RESERVATIONS,
  UPDATE_RESERVATION_EVENTS,
  REMOVE_RESERVATION_EVENTS,
  ADD_RESERVATION_EVENT,
  REMOVE_RESERVATION_EVENT,
} = constants;

const initialState = {
  reservations: {},
  reservationEvents: {},
};

export default (state = initialState, action) => {
  if (!action) return state;
  const { type } = action;
  switch (type) {
    case UPDATE_RESERVATIONS:
      return { ...state, reservations: { ...action.data } };
    case REMOVE_RESERVATIONS:
      return { ...state, reservations: {} };
    case UPDATE_RESERVATION_EVENTS:
      return { ...state, reservationEvents: { ...action.data } };
    case REMOVE_RESERVATION_EVENTS:
      return { ...state, reservationEvents: {} };
    case ADD_RESERVATION_EVENT: {
      const { eventId, event } = action.data;
      const { reservationEvents } = state;
      reservationEvents[eventId] = event;
      return { ...state, reservationEvents: { ...reservationEvents } };
    }
    case REMOVE_RESERVATION_EVENT: {
      const { eventId } = action.data;
      const { reservationEvents } = state;
      if (Object.hasOwnProperty.call(reservationEvents, eventId)) {
        delete reservationEvents[eventId];
        const eventKeys = Object.keys(reservationEvents);
        if (eventKeys.length < 1) {
          return { ...state, reservationEvents: {} };
        }
        return { ...state, reservationEvents: { ...reservationEvents } };
      }
      return state;
    }
    default:
      return state;
  }
};

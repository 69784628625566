import constants from "../constants";

const {
  GET_TREND_DATA,
  SET_TREND_DATA,
  SET_TRENDS,
  SET_TRENDS_HISTORY,
  STORE_TREND_HISTORY,
  REMOVE_TREND_DATA,
  GET_TREND_DATA_XLSX,
} = constants;

export const getTrendData = (data) => ({
  type: GET_TREND_DATA,
  data,
});

export const getTrendDataXlsx = (data) => ({
  type: GET_TREND_DATA_XLSX,
  data,
});

export const setTrendData = (data) => ({
  type: SET_TREND_DATA,
  data,
});

export const setTrends = (data) => ({
  type: SET_TRENDS,
  data,
});

export const setTrendsHistory = (data) => ({
  type: SET_TRENDS_HISTORY,
  data,
});

export const storeTrendHistory = (data) => ({
  type: STORE_TREND_HISTORY,
  data,
});

export const removeTrendData = (data) => ({
  type: REMOVE_TREND_DATA,
  data,
});

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";
import { Field, reduxForm } from "redux-form";

// Settings page components
import FormField from "components/FormField";

// NewUser page components

const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "onnaId"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

function OnnaDeviceForm(props) {
  const { handleSubmit, pristine, onSubmit, actionType } = props;
  const { t } = useTranslation();
  return (
    <MDBox py={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: "visible" }}>
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox
              height="4rem"
              bgColor="info"
              variant="gradient"
              coloredShadow="info"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-3}
              mx={10}
              px={2}
            >
              {t(actionType)} {t("onnaDevice")}
            </MDBox>
            <MDBox pt={3} pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="name" component={FormField} label={t("name")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="onnaId"
                    component={FormField}
                    disabled={actionType === "edit"}
                    label={t("onnaId")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="center" mt={2} xs={12}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      size="small"
                      disabled={pristine}
                    >
                      {t("Save")}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </form>
    </MDBox>
  );
}

OnnaDeviceForm.defaultProps = {};

OnnaDeviceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { actionType, uids } = props;
  const { onnaDevicesReducer } = state;
  let onnaDeviceDataToEdit = {};
  if (actionType === "edit" && uids.length === 1) {
    if (Object.prototype.hasOwnProperty.call(onnaDevicesReducer, uids[0]))
      onnaDeviceDataToEdit = onnaDevicesReducer[uids[0]];
  }
  return {
    initialValues: onnaDeviceDataToEdit,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "OnnaDeviceForm", // a unique identifier for this form
    validate,
  })(OnnaDeviceForm)
);
